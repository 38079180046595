import { Component, OnInit } from '@angular/core';
import { Order } from '../order';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Servererror } from '../servererror';
import { ProductService } from '../product.service';
import { formatDate } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { Csrftoken } from '../csrftoken';
import { AppService } from '../app.service';

@Component({
  selector: 'app-dispatchedorders',
  templateUrl: './dispatchedorders.component.html',
  styleUrls: ['./dispatchedorders.component.css']
})
export class DispatchedordersComponent implements OnInit {

  constructor(private productService: ProductService, private appService: AppService) { }

  orders: Array<Order> = [];
  serverError: Servererror = new Servererror();
  order = new Order();
  startDate: string = "";
  endDate: string = "";
  productId = "";
  orderId = "";
  previous = false;
  firstResult = 0;
  maxResult = 4;
  csrfToken : Csrftoken = new Csrftoken();

  ngOnInit() {
    this.firstResult = 0;
    this.maxResult = 4;
    this.searchDispatchedOrders();
    this.getToken();
  }

  dispatchedordersearchform = new FormGroup({
    start_date: new FormControl(),
    end_date: new FormControl()
  })

  searchDispatchedOrders() {
    const format = 'dd-MM-yyyy';
    const locale = 'en-US';
    this.startDate = "";
    this.endDate = "";
    if (this.StartDate.value != null && this.StartDate.value !== "") {
      this.startDate = formatDate(this.StartDate.value, format, locale);
    }
    if (this.EndDate.value != null && this.EndDate.value !== "") {
      this.endDate = formatDate(this.EndDate.value, format, locale);
    }

    this.productService.getDispatchedOrdersByDate(this.startDate, this.endDate, this.firstResult, this.maxResult).subscribe(
      data => {
        console.log(data);
        this.orders = data;
        Swal.fire({
          icon: 'success',
          title: "Success",
          text: "Dispatched Orders are loaded successfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: "Dispatched Orders are failed to load",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#032127",
          confirmButtonColor: "#032127",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        if (this.firstResult !== 0) {
          this.firstResult = this.firstResult - this.maxResult;
        }
        if (this.firstResult == 0) {
          this.previous = false;
        }
      });

  }

  deliverOrder(orderId: number) {
    this.order = new Order();
    const format = 'dd-MM-yyyy';
    const locale = 'en-US';
    const date = Date.now();

    this.order.order_id = orderId;
    this.order.status = "DELIVERED";
    this.order.delivered_date = formatDate(date, format, locale)
    this.productService.updateOrder(this.order, this.csrfToken).subscribe(
      data => {
        console.log(data);
        Swal.fire({
          icon: 'success',
          title: "Success",
          text: "Order status is updated to delivered successfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.searchDispatchedOrders();
        this.getToken();
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: "Order status is failed to update",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#032127",
          confirmButtonColor: "#032127",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      });
  }

  nextDispatchedOrders() {
    this.firstResult = this.firstResult + this.maxResult;
    this.previous = true;
    this.searchDispatchedOrders();   
  }

  previousDispatchedOrders() {
    this.firstResult = this.firstResult - this.maxResult;
    if (this.firstResult == 0) {
      this.previous = false;
    }
    this.searchDispatchedOrders();
  }

  getToken() {
    this.appService.getToken().subscribe(
      data => {
        this.csrfToken = data;
        console.log(data);
      },
      error => {
        console.log(error);
        
      });
  }

  get StartDate() {
    return this.dispatchedordersearchform.get('start_date');
  }

  get EndDate() {
    return this.dispatchedordersearchform.get('end_date');
  }

}
