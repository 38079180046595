import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalService } from './local.service';
import { Csrftoken } from './csrftoken';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  //private baseUrl = 'http://192.168.68.60:8080/api/admin';
  //private baseUrl = 'http://localhost:8080/api/admin';
  //private baseUrl = 'http://testdemo-env.eba-mfqf3qy3.us-east-1.elasticbeanstalk.com/api/admin';
  private baseUrl = 'https://api.esshoppy.com/api/admin';

  constructor(private http: HttpClient, private localService: LocalService) { }

  adminAuth(loginCredentials: object, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    return this.http.post(`${this.baseUrl}` + '/authenticate', loginCredentials,
      {  headers: new HttpHeaders().set('X-XSRF-TOKEN', csrf_token), withCredentials:true }
    );
  }

  adminLogin(loginCredentials: object): Observable<any> {
    return this.http.post(`${this.baseUrl}` + '/login-admin', loginCredentials);
  }

  addUser(user: object, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.post(`${this.baseUrl}` + '/add-user', user,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) 
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
      });
  }

  getUsers(): Observable<any> {
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.get(`${this.baseUrl}/get-users`,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) });
  }

  deleteUser(userId: any, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.delete(`${this.baseUrl}/delete-user/${userId}`,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) 
        .set('X-XSRF-TOKEN', csrf_token),
      withCredentials:true 
      });
  }

  getUser(userId: any): Observable<any> {
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.get(`${this.baseUrl}/get-user/${userId}`,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) });
  }

  updateUser(user: object, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.put(`${this.baseUrl}` + '/update-user', user,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) 
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
      });
  }

  updateUserPassword(userCred: object, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.put(`${this.baseUrl}` + '/updateuser-password', userCred,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) 
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
      });
  }

  userLogin(loginCredentials: object, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    return this.http.post(`${this.baseUrl}` + '/user-login', loginCredentials,
      {  headers: new HttpHeaders().set('X-XSRF-TOKEN', csrf_token), withCredentials:true }
    );
  }


}
