import { Image } from './image';

export class Product {
    product_id:number;
    product_code:string;
    product_name=String;
    product_desc:string;
    added_date:string;
    status_changed:boolean;
    price:bigint;
    discount:number;
    priceafter_discount:bigint;
    quantity:number;
    size:string;
    supersubcategory_id:number;
    status:string;
    images: Image[];
    cat_id:number;
    subcat_id:number;
}
