import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { ProductListComponent } from './product-list/product-list.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ConfirmEqualValidatorDirective } from './confirm-equal-validator.directive';
import { SafeHtml } from './safehtml';
import { MatGridListModule } from '@angular/material/grid-list';
import { ProfileComponent } from './profile/profile.component';
import { ProductComponent } from './product/product.component';
import { AddressComponent } from './address/address.component';
import { OrderComponent } from './order/order.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CartComponent } from './cart/cart.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderConfig, NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule, SPINNER } from 'ngx-ui-loader';
import { AdminComponent } from './admin/admin.component';
import { CategoryComponent } from './category/category.component';
import { SubcategoryComponent } from './subcategory/subcategory.component';
import { SupersubcategoryComponent } from './supersubcategory/supersubcategory.component';
import { ManageproductsComponent } from './manageproducts/manageproducts.component';
import { ManageordersComponent } from './manageorders/manageorders.component';
import { ManagedeliveredorderComponent } from './managedeliveredorder/managedeliveredorder.component';
import { ManagecustomersComponent } from './managecustomers/managecustomers.component';
import { SearchPipe } from './search.pipe';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { ManageusersComponent } from './manageusers/manageusers.component';
import { DispatchedordersComponent } from './dispatchedorders/dispatchedorders.component';
import { AppSettingsComponent } from './app-settings/app-settings.component';
import { CacheService } from './cache.service';
import { CacheInterceptor } from './CacheInterceptor';
import { AllproductsComponent } from './allproducts/allproducts.component';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  text: "Loading...",
  textColor: "#FFFFFF",
  textPosition: "center-center",
  bgsColor: "#0f3144",
  fgsColor: "#0f3144",
  fgsType: SPINNER.ballScaleMultiple,
  fgsSize: 100,
  hasProgressBar: false
}




@NgModule({
  declarations: [
    AppComponent,
    ProductListComponent,
    LoginComponent,
    SignupComponent,
    ConfirmEqualValidatorDirective,
    SafeHtml,
    ProfileComponent,
    ProductComponent,
    AddressComponent,
    OrderComponent,
    ForgotPasswordComponent,
    CartComponent,
    AdminComponent,
    CategoryComponent,
    SubcategoryComponent,
    SupersubcategoryComponent,
    ManageproductsComponent,
    ManageordersComponent,
    ManagedeliveredorderComponent,
    ManagecustomersComponent,
    SearchPipe,
    ManageusersComponent,
    DispatchedordersComponent,
    AppSettingsComponent,
    AllproductsComponent
  ],
  entryComponents: [SignupComponent, LoginComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    //HttpClientXsrfModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN'
    }),
    DataTablesModule,
    MatGridListModule,MatFormFieldModule, MatSelectModule,
    NgxPaginationModule,
    Ng2OrderModule,
    MatDialogModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    //NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule.forRoot({ showForeground: true })

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    CacheService],
  bootstrap: [AppComponent]
})
export class AppModule { }
