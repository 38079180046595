import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../admin.service';
import { User } from '../user';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Servererror } from '../servererror';
import { Observable } from 'rxjs';
import { UserCred } from '../user-cred';
import { Csrftoken } from '../csrftoken';
import { AppService } from '../app.service';

@Component({
  selector: 'app-manageusers',
  templateUrl: './manageusers.component.html',
  styleUrls: ['./manageusers.component.css']
})
export class ManageusersComponent implements OnInit {

  constructor(private adminService: AdminService, private appService: AppService) { }

  password;
  show = false;
  user: User = new User();
  userCred: UserCred = new UserCred();
  serverError: Servererror = new Servererror();
  users: Observable<User[]>;
  csrfToken : Csrftoken = new Csrftoken();

  ngOnInit() {
    this.password = 'password';
    this.getUsers();
  }

  adduserform = new FormGroup({
    user_id: new FormControl(),
    user_name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]),
    email: new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]),
    phone_num: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]),
    password: new FormControl('', [Validators.required, Validators.pattern("^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{7,15}$")]),
    confirm_password: new FormControl('', [Validators.required]),
    role: new FormControl(),
    enabled: new FormControl()
  });

  changePasswordform = new FormGroup({
    user_id: new FormControl(),
    chngpassword: new FormControl('', [Validators.required, Validators.pattern("^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$")]),
    chngconfirm_password: new FormControl('', [Validators.required])
  });

  addUser(addUser) {
    this.user = new User();
    this.user.user_name = this.UserName.value;
    this.user.email = this.Email.value;
    this.user.phone_num = this.PhoneNum.value;
    this.user.password = this.Password.value;
    this.user.enabled = this.Enabled.value;

    this.adminService.addUser(this.user, this.csrfToken).subscribe(
      data => {
        console.log(data);
        Swal.fire({
          icon: 'success',
          title: "Success",
          text: "User added sucessfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        (<any>$('#addUserModal')).modal('hide');
        this.getUsers();
      },
      error => {
        console.log(error)
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: "Failed to add user",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      });

    console.log(this.user);

  }

  changePassword(pass){
    this.userCred = new UserCred;
    this.userCred.userId = this.PassUserId.value;
    this.userCred.password = this.ChangePassword.value;
    console.log(this.PassUserId.value);
    console.log(this.ChangePassword.value);

    this.adminService.updateUserPassword(this.userCred, this.csrfToken).subscribe
      (data => {
        console.log(data);
        Swal.fire({
          icon: 'success',
          title: "Success",
          text:"User password updated sucessfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        (<any>$('#chngPwdModal')).modal('hide');
        this.getUsers();
      },
        error => {
          console.log(error)
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: "Failed to update user password",
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
          this.getToken();
        });
  }

  getUsers() {
    this.adminService.getUsers().subscribe
      (data => {
        this.users = data;
        Swal.fire({
          icon: 'success',
          title: "Success",
          text: "Users loaded sucessfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      },
        error => {
          console.log(error)
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: "Failed to load users",
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
          this.getToken();
        });
  }

  deleteUser(userId) {
    this.adminService.deleteUser(userId, this.csrfToken).subscribe
      (data => {
        Swal.fire({
          icon: 'success',
          title: "Success",
          text: "User deleted sucessfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.getUsers();
      },
        error => {
          console.log(error)
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: "Failed to delete user",
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
          this.getToken();
        });
  }

  updateUser(userId) {
    this.getUser(userId);
  }

  getUser(userId) {
    this.adminService.getUser(userId).subscribe
      (data => {
        this.user = data;
        console.log(data);
      },
        error => {
          console.log(error)
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: "Failed to load user",
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
        });
  }

  updUser(upduser) {
    this.user = new User();
    this.user.user_id = this.UserId.value;
    this.user.user_name = this.UserName.value;
    this.user.email = this.Email.value;
    this.user.phone_num = this.PhoneNum.value;
    this.user.role = this.Role.value;
    this.user.enabled = this.Enabled.value;

    console.log(this.user);
    this.adminService.updateUser(this.user, this.csrfToken).subscribe
      (data => {
        console.log(data);
        Swal.fire({
          icon: 'success',
          title: "Success",
          text:"User updated sucessfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        (<any>$('#myUpdUserModal')).modal('hide');
        this.getUsers();
      },
        error => {
          console.log(error)
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: "Failed to update user",
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
          this.getToken();
        });
  }

  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

  getToken() {
    this.appService.getToken().subscribe(
      data => {
        this.csrfToken = data;
        console.log(data);
      },
      error => {
        console.log(error);
        
      });
  }

  onAddClick() {
    this.adduserform.reset();
  }

  get UserId() {
    return this.adduserform.get('user_id');
  }

  get UserName() {
    return this.adduserform.get('user_name');
  }

  get Email() {
    return this.adduserform.get('email');
  }

  get PhoneNum() {
    return this.adduserform.get('phone_num');
  }

  get Password() {
    return this.adduserform.get('password');
  }

  get ConfirmPassword() {
    return this.adduserform.get('confirm_password');
  }

  get Role() {
    return this.adduserform.get('role');
  }

  get Enabled() {
    return this.adduserform.get('enabled');
  }

  get ChangePassword() {
    return this.changePasswordform.get('chngpassword');
  }

  get ChangeConfirmPassword() {
    return this.changePasswordform.get('chngconfirm_password');
  }

  get PassUserId(){
    return this.changePasswordform.get('user_id');
  }


}
