import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerService } from '../customer.service';
import { LoginCredentials } from '../login-credentials';
import { PhoneVerify } from '../phone-verify';
import { Servererror } from '../servererror';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LocalService } from '../local.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AppService } from '../app.service';
import { Csrftoken } from '../csrftoken';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private customerService: CustomerService, private localService: LocalService,
    private router: Router, private dialog: MatDialog, private appService: AppService) { }

  password;
  show = false;
  customerDetails: LoginCredentials = new LoginCredentials();
  phoneVerify: PhoneVerify = new PhoneVerify();
  serverError: Servererror = new Servererror();
  customer_id;
  public screenWidth: any;
  count = 0;
  csrfToken: Csrftoken = new Csrftoken();

  ngOnInit() {
    this.count = 0;
    this.show = false;
    this.password = 'password';
    this.getToken();
  }

  forgotpasswordform = new FormGroup({
    phone_num: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]),
    password: new FormControl('', [Validators.required, Validators.pattern("^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{7,15}$")]),
    confirm_password: new FormControl('', [Validators.required]),
    otp: new FormControl()
  })

  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

  changePassword(customerDetails) {
    this.customerDetails = new LoginCredentials();
    this.phoneVerify = new PhoneVerify();
    this.customerDetails.phonenum = this.PhoneNum.value;
    this.phoneVerify.phone_no = this.PhoneNum.value;
    this.customerDetails.password = this.Password.value;

    this.customerService.checkValidCustomer(this.customerDetails, this.csrfToken).subscribe
      (data => {
        console.log(data);
        this.getToken();
        this.verifyPhone();
      },
        error => {
          console.log(error);
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: "Phone number verification is failed",
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
          this.getToken();
        });
  }

  verifyPhone() {
    this.customerService.verifyPhone(this.phoneVerify, this.csrfToken)
      .subscribe
      (data => {
        this.getToken();
        console.log(data);
        this.count = 0;
        this.enterOtp();
      },
        error => {
          console.log(error)
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: "Phone number verification is failed",
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
          this.getToken();
        });
  }

  verifyOtp(otp: string) {
    this.phoneVerify.otp = otp;
    this.customerService.verifyOtp(this.phoneVerify, this.csrfToken).subscribe
      (data => {
        this.getToken();
        console.log(data);
        this.updatePassword(otp);
      },
        error => {
          console.log(error);
          console.log(this.count);
          this.count++;
          console.log(this.count);
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: "OTP verification is failed",
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
          this.getToken();

          if (this.count<3) {
            this.enterOtp()
          } else {
            Swal.fire({
              icon: 'error',
              title: "OTP verification is failed",
              text: "you have exceeded the limit please try again after some time",
              iconColor: "#892205",
              color: "#2b617d",
              confirmButtonColor: "#2b617d",
              showConfirmButton: true,
              background: "#e5e6e6ed",
            });
          }

        });
  }

  async enterOtp() {
    const { value: otp } = await Swal.fire({
      title: 'Enter your OTP',
      input: 'password',
      inputLabel: 'OTP',
      inputPlaceholder: 'Enter your OTP',
      confirmButtonColor: "#2b617d",
      showConfirmButton: true,
      inputAttributes: {
        maxlength: 10,
        autocapitalize: 'off',
        autocorrect: 'off'
      }
    })

    if (otp) {
      this.verifyOtp(otp);
    }else{
      Swal.fire({
        icon: 'error',
        title: "OTP verification is failed",
        text: "please enter OTP and submit",
        iconColor: "#892205",
        color: "#2b617d",
        confirmButtonColor: "#2b617d",
        showConfirmButton: true,
        background: "#e5e6e6ed",
      });
      this.enterOtp();
    }
  }

  updatePassword(otp: string) {
    this.customerService.updatePassword( this.customerDetails, otp, this.csrfToken).subscribe
      (data => {
        console.log(data);
        this.dialog.closeAll();
        Swal.fire({
          icon: 'success',
          title: "Password updated successfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.customer_id = this.localService.getData('customerId');
        if (this.customer_id != null) {
          this.localService.clearData();
          this.router.navigate(['view-products']).then(() => {
          window.location.reload();});
        }

      },
        error => {
          console.log(error);
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: "Password update is failed",
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
          this.getToken();
        });
  }

  getToken() {
    this.appService.getToken().subscribe(
      data => {
        this.csrfToken = data;
        console.log(data);
      },
      error => {
        console.log(error);
        
      });
  }


  clickButton(path: string) {
    this.router.navigate([path]);
  }

  get PhoneNum() {
    return this.forgotpasswordform.get('phone_num');
  }

  get Password() {
    return this.forgotpasswordform.get('password');
  }

  get ConfirmPassword() {
    return this.forgotpasswordform.get('confirm_password');
  }

  get Otp() {
    return this.forgotpasswordform.get('otp');
  }

}
