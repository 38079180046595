import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Address } from '../address';
import { Corv } from '../corv';
import { CustomerService } from '../customer.service';
import { Taluk } from '../taluk';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LocalService } from '../local.service';
import { AppService } from '../app.service';
import { Csrftoken } from '../csrftoken';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {


  constructor(private customerService: CustomerService, private appService: AppService,
    private localService: LocalService, private router: Router) { }

  address: Address = new Address();
  defaultState;
  defaultDistrict;
  taluks: Observable<Taluk[]>;
  corvs: Observable<Corv[]>;
  talukname;
  corvname;
  serverError: any;
  product_id;
  customer_id;
  dialogRef: any;
  csrfToken : Csrftoken = new Csrftoken();

  ngOnInit() {
    this.defaultState = 'Karnataka';
    this.defaultDistrict = 'Tumkur';
    this.getToken();

    this.appService.getTaluksById(1).subscribe(data => {
      this.taluks = data;
    })
  }

  addressform = new FormGroup({
    pincode: new FormControl(),
    landmark: new FormControl()

  });

  createAddress(addressInformation) {
    this.address = new Address();

    this.address.state = this.defaultState;
    this.address.district = this.defaultDistrict;
    this.address.taluk = this.talukname;
    this.address.pincode = this.Pincode.value;
    this.address.corv = this.corvname;
    this.address.landmark = this.Landmark.value;

    if (this.localService.getData('customerId') != null) {
      this.customer_id = this.localService.getData('customerId');
    }

    if (this.customer_id != null) {
      this.save();
    } else {
      this.router.navigate(['view-products']);
    }
  }

  save() {
    this.customerService.addAddress(this.address, this.customer_id, this.csrfToken).subscribe(
      data => {
        console.log(data)
        this.addressform.reset();
        Swal.fire({
          icon: 'success',
          title:'Success',
          text: "Address added sucessfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });

        window.location.reload();

      },
      error => {
        console.log(error);
        this.serverError = error.error;

        Swal.fire({
          icon: 'error',
          title: "Failed to add address",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      });
  }

  getcorv(selectedTaluk: Taluk) {
    this.talukname = selectedTaluk.taluk_name;
    console.log(this.talukname);
    this.appService.getCorvs(selectedTaluk.taluk_id).subscribe(data => {
      this.corvs = data;
    })
  }

  setcorv(corv_name: string) {
    this.corvname = corv_name;
    console.log(this.corvname);
  }

  getToken() {
    this.appService.getToken().subscribe(
      data => {
        this.csrfToken = data;
        console.log(data);
      },
      error => {
        console.log(error);
        
      });
  }

  get Pincode() {
    return this.addressform.get('pincode');
  }

  get Landmark() {
    return this.addressform.get('landmark');
  }

}
