import { Component, OnInit } from '@angular/core';
import { ProductService } from '../product.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Deliveredorders } from '../deliveredorders';
import { Servererror } from '../servererror';
import { FormControl, FormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-managedeliveredorder',
  templateUrl: './managedeliveredorder.component.html',
  styleUrls: ['./managedeliveredorder.component.css']
})
export class ManagedeliveredorderComponent implements OnInit {

  constructor(private productService: ProductService) { }

  deliveredOrders: Array<Deliveredorders> = [];
  serverError: Servererror = new Servererror();
  startDate: string = "";
  endDate: string = "";
  previous = false;
  firstResult = 0;
  maxResult = 8;

  ngOnInit() {
    this.firstResult = 0;
    this.maxResult = 8;
    this.searchDeliveredOrders();
  }

  deliveredordersearchform = new FormGroup({
    start_date: new FormControl(),
    end_date: new FormControl()
  })

  searchDeliveredOrders() {
    const format = 'dd-MM-yyyy';
    const locale = 'en-US';
    this.startDate = "";
    this.endDate = "";
    if (this.StartDate.value != null && this.StartDate.value !== "") {
      this.startDate = formatDate(this.StartDate.value, format, locale);
    }
    if (this.EndDate.value != null && this.EndDate.value !== "") {
      this.endDate = formatDate(this.EndDate.value, format, locale);
    }

    this.productService.getDeliveredOrdersByDate(this.startDate, this.endDate, this.firstResult, this.maxResult).subscribe(
      data => {
        console.log(data);
        this.deliveredOrders = data;
        Swal.fire({
          icon: 'success',
          title: "Success",
          text:"Delivered Orders are loaded successfully",
          iconColor: "#2b617d",
        color: "#2b617d",
        confirmButtonColor: "#2b617d",
        showConfirmButton: true,
        timer: 1500,
        timerProgressBar: true,
        background: "#e5e6e6ed",
        });
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: "Delivered Orders are failed to load",
          text: this.serverError.message,
          iconColor: "#892205",
        color: "#2b617d",
        confirmButtonColor: "#2b617d",
        showConfirmButton: true,
        background: "#e5e6e6ed",
        });
        if (this.firstResult !== 0) {
          this.firstResult = this.firstResult - this.maxResult;
        }
        if (this.firstResult == 0) {
          this.previous = false;
        }
      });
  }

  nextDeliveredOrders() {
    this.firstResult = this.firstResult + this.maxResult;
    this.previous = true;
    this.searchDeliveredOrders();
  }

  previousDeliveredOrders() {
    this.firstResult = this.firstResult - this.maxResult;
    if (this.firstResult == 0) {
      this.previous = false;
    }
    this.searchDeliveredOrders();
  }

  get StartDate() {
    return this.deliveredordersearchform.get('start_date');
  }

  get EndDate() {
    return this.deliveredordersearchform.get('end_date');
  }

}
