import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { LocalService } from './local.service';
import { AppService } from './app.service';
import { Csrftoken } from './csrftoken';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'SpringSkyFashion';
  loggedInCustomer: string = null;
  customer_id;
  product_id;
  address_id;
  token;
  loggedInAdmin: string = null;
  loggedInUser: string = null;
  userId;
  public screenWidth: any;

  constructor(private router: Router, private dialog: MatDialog, private localService: LocalService) { }

  ngOnInit() {

    if (this.localService.getData('customerId') != null && this.localService.getData('token') != null) {
      this.loggedInCustomer = this.localService.getData('customerId');
    }

    if (this.localService.getData('customerId') == null && this.localService.getData('token') != null) {
      this.loggedInAdmin = this.localService.getData('token');
    }

    if (this.localService.getData('userId') != null && this.localService.getData('token') != null) {
      this.loggedInUser = this.localService.getData('userId');
    }
  }

  logout() {

    this.customer_id = this.localService.getData('customerId');
    if (this.customer_id != null) {
      this.localService.clearData();
    }

    this.token = this.localService.getData('token');
    if (this.token != null) {
      this.localService.clearData();
    }

    this.userId = this.localService.getData('userId');
    if (this.userId != null) {
      this.localService.clearData();
    }

    this.product_id = this.localService.getData('productId');
    if (this.product_id != null) {
      this.localService.clearData();
    }

    this.router.navigate(['view-products']).then(() => {
      window.location.reload();
    });

  }

  viewProducts() {
    this.router.navigate(['view-products']);
  }

  searchProducts(text:string){
    this.router.navigate(['view-products'],{queryParams:{search:text}}).then(() => {
      window.location.reload();
    });

  }

  handleSignupAction() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50vw";
    this.screenWidth = window.innerWidth;
    dialogConfig.panelClass = "custom-modalbox";
    if(this.screenWidth <= 700){
      dialogConfig.width = "100vw";
    }
    this.dialog.open(SignupComponent, dialogConfig);
  }

  handleLoginAction() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50vw";
    //dialogConfig.height = "300px";
    this.screenWidth = window.innerWidth;
    dialogConfig.panelClass = "custom-modalbox";
    if(this.screenWidth <= 700){
      dialogConfig.width = "100vw";
    }
    this.dialog.open(LoginComponent, dialogConfig);
  }


}
