import { Customerdetails } from "./customerdetails";
import { Product } from "./product";

export class Order {
    order_id : number;
    product_img : Blob;
    product_name : string;
    price : bigint;
    status : string;
    admin_id: number;
    //product_id: number;
    ordered_date: string;
    dispatched_date: string;
    delivered_date:string;
    customer: Customerdetails;
    product:Product;
}
