import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductListComponent } from './product-list/product-list.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ProfileComponent } from './profile/profile.component';
import { ProductComponent } from './product/product.component';
import { AddressComponent } from './address/address.component';
import { OrderComponent } from './order/order.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CartComponent } from './cart/cart.component';
import { RouteGuardService } from './route-guard.service';
import { AdminComponent } from './admin/admin.component';
import { CategoryComponent } from './category/category.component';
import { SubcategoryComponent } from './subcategory/subcategory.component';
import { SupersubcategoryComponent } from './supersubcategory/supersubcategory.component';
import { ManageproductsComponent } from './manageproducts/manageproducts.component';
import { ManageordersComponent } from './manageorders/manageorders.component';
import { ManagedeliveredorderComponent } from './managedeliveredorder/managedeliveredorder.component';
import { ManagecustomersComponent } from './managecustomers/managecustomers.component';
import { ManageusersComponent } from './manageusers/manageusers.component';
import { DispatchedordersComponent } from './dispatchedorders/dispatchedorders.component';
import { AppSettingsComponent } from './app-settings/app-settings.component';
import { AllproductsComponent } from './allproducts/allproducts.component';


const routes: Routes = [
  { path: '', redirectTo: 'view-products', pathMatch: 'full' },
  { path: 'view-products', component: ProductListComponent },
  { path: 'login', component: LoginComponent },
  { path: 'sign-up', component: SignupComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'product', component: ProductComponent },
  { path: 'address', component: AddressComponent },
  { path: 'orders', component: OrderComponent },
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  { path: 'cart', component: CartComponent },
  { path: 'dispatched-orders', component: DispatchedordersComponent },
  {
    path: 'admin', canActivate: [RouteGuardService], data: { expectedRole: ['ROLE_ADMIN'] }, component: AdminComponent, children: [
      { path: 'category', canActivate: [RouteGuardService], data: { expectedRole: ['ROLE_ADMIN'] }, component: CategoryComponent },
      { path: 'subcategory', canActivate: [RouteGuardService], data: { expectedRole: ['ROLE_ADMIN'] }, component: SubcategoryComponent },
      { path: 'supersubcategory', canActivate: [RouteGuardService], data: { expectedRole: ['ROLE_ADMIN'] }, component: SupersubcategoryComponent },
      { path: 'manageproducts', canActivate: [RouteGuardService], data: { expectedRole: ['ROLE_ADMIN'] }, component: ManageproductsComponent },
      { path: 'allproducts', canActivate: [RouteGuardService], data: { expectedRole: ['ROLE_ADMIN'] }, component: AllproductsComponent },
      { path: 'manageorders', canActivate: [RouteGuardService], data: { expectedRole: ['ROLE_ADMIN'] }, component: ManageordersComponent },
      { path: 'managedispatchedorders', canActivate: [RouteGuardService], data: { expectedRole: ['ROLE_ADMIN'] }, component: DispatchedordersComponent },
      { path: 'managedeliveredorders', canActivate: [RouteGuardService], data: { expectedRole: ['ROLE_ADMIN'] }, component: ManagedeliveredorderComponent },
      { path: 'managecustomers', canActivate: [RouteGuardService], data: { expectedRole: ['ROLE_ADMIN'] }, component: ManagecustomersComponent },
      { path: 'manageusers', canActivate: [RouteGuardService], data: { expectedRole: ['ROLE_ADMIN'] }, component: ManageusersComponent },
      { path: 'appsettings', canActivate: [RouteGuardService], data: { expectedRole: ['ROLE_ADMIN'] }, component: AppSettingsComponent },
      { path: 'addcustomer', canActivate: [RouteGuardService], data: { expectedRole: ['ROLE_ADMIN'] }, component: SignupComponent }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
