import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalService } from './local.service';
import { Csrftoken } from './csrftoken';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  //private baseUrl = 'http://192.168.68.60:8080/api';
  //private baseUrl = 'http://localhost:8080/api';
  //private baseUrl = 'http://testdemo-env.eba-mfqf3qy3.us-east-1.elasticbeanstalk.com/api';
  private baseUrl = 'https://api.esshoppy.com/api';
  
  //private baseUrl = '/api';
  //private _cookieService: any;

  constructor(private http: HttpClient, private localService: LocalService, private tokenExtractor: HttpXsrfTokenExtractor) { }

  addCustomer( customer: object, otp_id:string, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    //return this.http.post(`${this.baseUrl}` + '/cust/add-customer/${otp_id}', customer);
    return this.http.post(`${this.baseUrl}/cust/add-customer/${otp_id}`, customer,
      {  headers: new HttpHeaders().set('X-XSRF-TOKEN', csrf_token), withCredentials:true }
    );

    //{ headers: new HttpHeaders().set('Content-Type', 'application/json') }
  }

  loginCustomer(loginCredentials: object, csrfToken: Csrftoken): Observable<any> {
    let csrf_Token = this.tokenExtractor.getToken() as string;
    console.log("csrf token by extractor"+ " "+csrf_Token);
    let csrf_token = csrfToken.token;
    return this.http.post(`${this.baseUrl}` + '/cust/login-customer', loginCredentials, 
      {  headers: new HttpHeaders().set('X-XSRF-TOKEN', csrf_token), withCredentials:true }
    );
  }

  getCustomer(customer_id: string): Observable<any> {
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.get(`${this.baseUrl}/cust/get-customer/${customer_id}`, 
    { headers: new HttpHeaders().set('Authorization', tokenBearer) });
  }

  getCustomerByPhNum(phone_num: any): Observable<any> {
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.get(`${this.baseUrl}/cust/get-customer-byphnum/${phone_num}`,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) });
  }

  getCustomers(firstRes: any, maxRes: any): Observable<any> {
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("firstResult", firstRes);
    queryParams = queryParams.append("maxResult", maxRes);
    return this.http.get(`${this.baseUrl}/cust/get-customers`,
      { params: queryParams, headers: new HttpHeaders().set('Authorization', tokenBearer) });
  }

  getAddressByCustId(customer_id: string): Observable<any> {
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.get(`${this.baseUrl}/cust/get-address/${customer_id}`, 
    { headers: new HttpHeaders().set('Authorization', tokenBearer) });
  }

  addAddress(address: object, customer_id: string, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.post(`${this.baseUrl}/cust/add-address/${customer_id}`, address, 
    { headers: new HttpHeaders().set('Authorization', tokenBearer)
      .set('X-XSRF-TOKEN', csrf_token),
      withCredentials:true}
    );
  }

  verifyPhone(phoneVerify: object, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    return this.http.post(`${this.baseUrl}` + '/ph/generateOTP', phoneVerify,
      { headers: new HttpHeaders().set('X-XSRF-TOKEN', csrf_token)
        , withCredentials:true }
    );
  }

  verifyOtp(phoneVerify: object, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    return this.http.post(`${this.baseUrl}` + '/ph/verifyOTP', phoneVerify,
      { headers: new HttpHeaders().set('X-XSRF-TOKEN', csrf_token)
        , withCredentials:true }
    );
  }

  updateCustomer(customer: object, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.put(`${this.baseUrl}` + '/cust/update-customer', customer,
    { headers: new HttpHeaders().set('Authorization', tokenBearer) 
      .set('X-XSRF-TOKEN', csrf_token)
      , withCredentials:true }
    );
  }

  checkValidCustomer(custCredentials: object, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    return this.http.post(`${this.baseUrl}` + '/cust/checkValid-customer', custCredentials, 
      { headers: new HttpHeaders().set('X-XSRF-TOKEN', csrf_token)
        , withCredentials:true }
    );
  }

  updatePassword(custCredentials: object, otp_id:string, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    return this.http.put(`${this.baseUrl}/cust/update-password/${otp_id}`, custCredentials,
      { headers: new HttpHeaders().set('X-XSRF-TOKEN', csrf_token)
        , withCredentials:true }
    );
  }

  updatePhoneNumber(custPhone: object, otp_id:string, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    return this.http.put(`${this.baseUrl}/cust/update-phnum/${otp_id}`, custPhone, 
      { headers: new HttpHeaders().set('X-XSRF-TOKEN', csrf_token)
        , withCredentials:true }
    );
  }



}
