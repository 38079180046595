import { Injectable } from '@angular/core';
import {HttpRequest, HttpResponse} from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})

abstract class HttpCache{

  abstract get(req: HttpRequest<any>): HttpResponse<any>|null;

  abstract put(req: HttpRequest<any>, resp: HttpResponse<any>): void;
}

export class CacheService implements HttpCache{

  private cache = {};

  constructor() { }

  put(req: HttpRequest<any>, resp: HttpResponse<any>): void{
    this.cache[req.urlWithParams] = resp;
  }

  get(req: HttpRequest<any>): HttpResponse<any>|null{
    return this.cache[req.urlWithParams];
  }
  
}
