import { Component, Input, OnInit } from '@angular/core';
import { ProductService } from '../product.service';
import { Category } from '../category';
import { Subcategory } from '../subcategory';
import { Observable } from "rxjs";
import { Product } from '../product';
import { ActivatedRoute, Router } from '@angular/router';
import { Servererror } from '../servererror';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LocalService } from '../local.service';
import { AppService } from '../app.service';


@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

  constructor(private productservice: ProductService, private appService: AppService,
    private router: Router, private route: ActivatedRoute, private localService: LocalService) { }

  categories: Array<Category> = [];
  subcategories: Observable<Subcategory[]>;
  category: Category;
  products: Observable<Product[]>;
  randomProducts: Observable<Product[]>;
  product: Product = new Product();
  customerId: string;
  serverError: Servererror = new Servererror();
  addressId: string;
  p: Number = 1;
  count: Number = 4;
  firstResult = 0;
  maxResult = 8;
  previous = false;
  searchText = "";
  defaultCategoryId = 0;
  subCategoryId = "";
  suprSubCategoryId = "";
  selectedIndex = 0;
  callFrmCat = false;
  breakpoint = 4;
  public screenWidth: any;

  @Input() indicators = true;
  @Input() controls = true;
  @Input() autoSlide = true;
  @Input() slideInterval = 5000;

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 700) {
      this.breakpoint = 2;
    }

    this.searchText = "";

    this.route.queryParams.subscribe((params: any) => {
      if (params.search != null) {
        this.searchText = params.search;
      }
    })

    this.previous = false;

    this.getCategories();

    this.getRandomProducts();

    if (this.autoSlide) {
      this.autoSlideImages();
    }
  }

  autoSlideImages(): void {
    setInterval(() => {
      this.onNextClick();
    }, this.slideInterval);
  }

  selectImage(index: number): void {
    this.selectedIndex = index;
  }

  onPrevClick(): void {
    if (this.selectedIndex === 0) {
      this.selectedIndex = 5 - 1;
    } else {
      this.selectedIndex--;
    }
  }

  onNextClick(): void {
    if (this.selectedIndex === 5 - 1) {
      this.selectedIndex = 0;
    } else {
      this.selectedIndex++;
    }
  }

  getCategories() {
    this.appService.getCategoryList().subscribe(
      data => {
        this.categories = data;
        for (let categorie of this.categories) {
          if (categorie.category_name == "Fashion") {
            this.defaultCategoryId = categorie.category_id;
            break;
          }
        }
        this.callFrmCat = true;
        this.getSubcategory(this.defaultCategoryId, this.callFrmCat);

      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: "Categories are failed to load",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
      })
  }

  getSubcategory(category_id: number, callFromCat: boolean) {
    this.defaultCategoryId = category_id;
    this.initialize();
    if (!callFromCat) {
      this.searchText = "";
    }
    if (this.defaultCategoryId != 0) {
      this.getSubcategoryList(category_id);
    }
    this.getProducts(this.defaultCategoryId, "", "", this.firstResult, this.maxResult, this.searchText);
  }

  getSubcategoryList(category_id: number) {
    this.appService.getSubcategoryList(category_id).subscribe(
      data => {
        this.subcategories = data;
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: "Sub-categories are failed to load",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
      })
  }

  getProduct(product_id: number) {
    this.localService.saveData('productId', product_id.toString());
    this.router.navigate(['product']);
  }

  getProducts(categoryId, subCategoryId, suprSubcategoryId, fr, mx, searchTxt) {
    this.productservice.getProducts(categoryId, subCategoryId, suprSubcategoryId, fr, mx, searchTxt).subscribe(
      data => {
        this.products = data;
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        if (this.firstResult !== 0) {
          this.firstResult = this.firstResult - this.maxResult;
        }
        if (this.firstResult == 0) {
          this.previous = false;
        }
        Swal.fire({
          icon: 'error',
          title: "Products are failed to load",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
      })
  }

  getRandomProducts() {
    this.productservice.getRandomProducts().subscribe(
      data => {
        this.randomProducts = data;
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: "Products are failed to load",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
      })
  }

  getProductBySubCatId(subcategory_id: string) {
    this.subCategoryId = subcategory_id;
    this.suprSubCategoryId = "";
    this.initialize();
    this.searchText = "";
    this.getProducts(this.defaultCategoryId, this.subCategoryId, this.suprSubCategoryId,
      this.firstResult, this.maxResult, this.searchText);
  }

  getProductsBySuprSubCatId(supersubcategory_id: string) {
    this.subCategoryId = "";
    this.suprSubCategoryId = supersubcategory_id;
    this.initialize();
    this.searchText = "";
    this.getProducts(this.defaultCategoryId, this.subCategoryId, this.suprSubCategoryId,
      this.firstResult, this.maxResult, this.searchText);
  }

  nextProducts() {
    this.firstResult = this.firstResult + this.maxResult;
    this.previous = true;
    this.getProducts(this.defaultCategoryId, this.subCategoryId, this.suprSubCategoryId,
      this.firstResult, this.maxResult, this.searchText);
  }

  previousProducts() {
    this.firstResult = this.firstResult - this.maxResult;
    if (this.firstResult == 0) {
      this.previous = false;
    }
    this.getProducts(this.defaultCategoryId, this.subCategoryId, this.suprSubCategoryId,
      this.firstResult, this.maxResult, this.searchText);
  }

  initialize() {
    this.firstResult = 0;
    this.previous = false;
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 700) ? 2 : 4;
  }

}
