import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from '../category';
import { Product } from '../product';
import { ProductService } from '../product.service';
import { Subcategory } from '../subcategory';
import { Supersubcategory } from '../supersubcategory';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Servererror } from '../servererror';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Image } from '../image';
import { AppService } from '../app.service';
import { Csrftoken } from '../csrftoken';

@Component({
  selector: 'app-manageproducts',
  templateUrl: './manageproducts.component.html',
  styleUrls: ['./manageproducts.component.css']
})
export class ManageproductsComponent implements OnInit {

  categories: Array<Category> = [];
  category = new Category();
  category_id = null;
  subcategories: Array<Subcategory> = [];
  subcategory = new Subcategory();
  subcategory_id = null;
  supersubcategory_id = null;
  supersubcategory = new Supersubcategory();
  //products: Observable<Product[]>;
  products: Array<Product> = [];
  deleteMessage = false;
  serverError: Servererror = new Servererror();
  product = new Product();
  dropdowns = false;
  url: any;
  image = new Image();
  productStatus = null;
  productStatusChanged = false;
  selected = "selectedOption";
  product_Id;
  csrfToken : Csrftoken = new Csrftoken();


  constructor(private productService: ProductService, private appService: AppService) { }

  ngOnInit() {
    this.selected = "selectedOption";

    this.getCategoryList();
    this.getToken();
  }

  getCategoryList() {
    this.appService.getCategoryList().subscribe(data => {
      this.categories = data;
    }, error => {
      this.serverError = error.error;
      Swal.fire({
        icon: 'error',
        title: this.serverError.error,
        text: this.serverError.message,
        iconColor: "#892205",
        color: "#2b617d",
        confirmButtonColor: "#2b617d",
        showConfirmButton: true,
        background: "#e5e6e6ed",
      });
    })
  }

  getsubcategory(category_id: number) {
    this.category_id = category_id;
    this.appService.getSubcategoryList(category_id).subscribe(data => {
      this.subcategories = data;
    }, error => {
      this.serverError = error.error;
      Swal.fire({
        icon: 'error',
        title: this.serverError.error,
        text: this.serverError.message,
        iconColor: "#892205",
        color: "#2b617d",
        confirmButtonColor: "#2b617d",
        showConfirmButton: true,
        background: "#e5e6e6ed",
      });
    })
  }

  getsupersubcategory(subcategoryId: number) {
    this.subcategory_id = subcategoryId;
    for (let subcategorie of this.subcategories) {
      if (subcategorie.subcategory_id == subcategoryId) {
        this.subcategory = subcategorie;
      }
    }
  }

  getProductsbyid(supersubcategoryId: number) {
    this.supersubcategory_id = supersubcategoryId;
    this.productService.getProductsBySuprSubCatId(supersubcategoryId).subscribe(data => {
      this.products = data;
    }, error => {
      this.serverError = error.error;
      Swal.fire({
        icon: 'error',
        title: this.serverError.error,
        text: this.serverError.message,
        iconColor: "#892205",
        color: "#2b617d",
        confirmButtonColor: "#2b617d",
        showConfirmButton: true,
        background: "#e5e6e6ed",
      });
      this.products = null;
    })
  }

  productupdateform = new FormGroup({
    product_id: new FormControl(),
    product_name: new FormControl('', [Validators.required]),
    product_desc: new FormControl(),
    added_date: new FormControl(),
    price: new FormControl(),
    discount: new FormControl(),
    price_after_discount: new FormControl(),
    quantity: new FormControl(),
    size: new FormControl(),
    status: new FormControl(),
    // product_image: new FormControl(),
    image_id: new FormControl(),
    supersubcategory_id: new FormControl('', [Validators.required])

  })

  deleteProduct(productId: number) {
    this.productService.deleteProduct(productId, this.csrfToken)
      .subscribe(
        data => {
          console.log(data);
          Swal.fire({
            icon: 'success',
            title: "Success",
            text: "product is deleted successfully",
            iconColor: "#2b617d",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            timer: 1500,
            timerProgressBar: true,
            background: "#e5e6e6ed",
          });
          this.productService.getProductsBySuprSubCatId(this.supersubcategory_id).subscribe(data => {
            this.products = data;
          })
          this.getToken();
        },
        error => {
          console.log(error);
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: this.serverError.error,
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
          this.getToken();
        });
  }

  updateProduct(productId: number) {
    this.getProduct(productId);
  }

  getProduct(productId: number){
    this.productService.getProduct(productId).subscribe(
      data => {
        console.log(data);
        this.product = data;
        for (let image of this.product.images) {
          this.image = image;
        }
        this.dropdowns = true;
        Swal.fire({
          icon: 'success',
          title: "Success",
          text: "Product is loaded successfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
      });
  }

  updateProd(updproduct) {
    //this.product = new Product();
    this.product.product_id = this.ProductId.value;
    this.product.product_name = this.ProductName.value;
    this.product.product_desc = this.ProductDesc.value;
    this.product.added_date = this.AddedDate.value;
    this.product.price = this.Price.value;
    this.product.discount = this.Discount.value;
    this.product.priceafter_discount = this.PriceAfterDiscount.value;
    this.product.quantity = this.Quantity.value;
    this.product.size = this.Size.value;
    //this.product.status = this.Status.value;
    //this.product.status = this.productStatus;
    //this.product.status_changed = this.productStatusChanged;
    if(this.category_id != null){
      this.product.cat_id = this.category_id;
    }
    if(this.subcategory_id != null){
      this.product.subcat_id = this.subcategory_id;
    }
    if(this.SuperSubCategoryId != null){
      this.product.supersubcategory_id = this.SuperSubCategoryId.value;
    }

    let images: Array<Image> = [];
    let image = new Image();
    if (this.url != null) {
      var splitted = this.url.split("data:image/jpeg;base64,");
      image.image = splitted[1];
      image.image_id = this.ImageId.value;
      image.product_id = this.ProductId.value;
      //image.product = this.product;

      images.push(image);
      this.product.images = images;

    }

    this.productService.updateProduct(this.product, this.csrfToken).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: "Success",
          text: "product is updated successfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.productupdateform.reset();
        (<any>$('#myUpdModal')).modal('hide');
        this.productService.getProductsBySuprSubCatId(this.supersubcategory_id).subscribe(data => {
          this.products = data;
        })
        this.getToken();
        this.url = null;
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      })
    this.url = null;

    console.log(this.product);
  }

  onselectFile(e) {
    if (e.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event: any) => {
        this.url = event.target.result;
        console.log(this.url);
      }
    }
  }

  setProductStatus(status: string) {
    this.product.status = status;
    this.product.status_changed = true;
  }

  addProduct(addProduct) {
    this.product = new Product();

    this.product.product_name = this.ProductName.value;
    this.product.product_desc = this.ProductDesc.value;
    //this.product.added_date = this.AddedDate.value;
    this.product.price = this.Price.value;
    this.product.discount = this.Discount.value;
    this.product.priceafter_discount = this.PriceAfterDiscount.value;
    this.product.quantity = this.Quantity.value;
    this.product.size = this.Size.value;
    this.product.cat_id = this.category_id;
    this.product.subcat_id = this.subcategory_id;
    this.product.supersubcategory_id = this.SuperSubCategoryId.value;

    let images: Array<Image> = [];
    let image = new Image();
    if (this.url != null) {
      var splitted = this.url.split("data:image/jpeg;base64,");
      image.image = splitted[1];

      images.push(image);
      this.product.images = images;

    }
    console.log(this.product);

    this.productService.addProduct(this.product, this.csrfToken).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: "Success",
          text: "product is added successfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.productupdateform.reset();
        (<any>$('#addProductModal')).modal('hide');
        this.productService.getProductsBySuprSubCatId(this.supersubcategory_id).subscribe(data => {
          this.products = data;
        })
        this.url = null;
        this.getToken();
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      })
    this.url = null;
  }

  searchProductById() {
    this.productService.getProduct(this.product_Id).subscribe(data => {
      this.product = data;
      this.products = [];
      this.products.push(this.product);
    }, error => {
      this.serverError = error.error;
      Swal.fire({
        icon: 'error',
        title: this.serverError.error,
        text: this.serverError.message,
        iconColor: "#892205",
        color: "#2b617d",
        confirmButtonColor: "#2b617d",
        showConfirmButton: true,
        timer: 1500,
        timerProgressBar: true,
        background: "#e5e6e6ed",
      });
    })
  }

  getToken() {
    this.appService.getToken().subscribe(
      data => {
        this.csrfToken = data;
        console.log(data);
      },
      error => {
        console.log(error);
        
      });
  }

  close() {
    this.productupdateform.reset();
  }

  get ProductId() {
    return this.productupdateform.get('product_id');
  }

  get ProductName() {
    return this.productupdateform.get('product_name');
  }

  get ProductDesc() {
    return this.productupdateform.get('product_desc');
  }

  get AddedDate() {
    return this.productupdateform.get('added_date');
  }

  get Price() {
    return this.productupdateform.get('price');
  }

  get Discount() {
    return this.productupdateform.get('discount');
  }

  get PriceAfterDiscount() {
    return this.productupdateform.get('price_after_discount');
  }

  get Quantity() {
    return this.productupdateform.get('quantity');
  }

  get Size() {
    return this.productupdateform.get('size');
  }

  get Status() {
    return this.productupdateform.get('status');
  }

  get ImageId() {
    return this.productupdateform.get('image_id');
  }

  get SuperSubCategoryId() {
    return this.productupdateform.get('supersubcategory_id');
  }

}
