import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Taluk } from '../taluk';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Corv } from '../corv';
import { Servererror } from '../servererror';
import { Csrftoken } from '../csrftoken';

@Component({
  selector: 'app-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.css']
})
export class AppSettingsComponent implements OnInit {

  taluks: Array<Taluk> = [];
  taluk = new Taluk();
  corv = new Corv();
  talukId;
  talukName;
  serverError: Servererror = new Servererror();
  csrfToken : Csrftoken = new Csrftoken();

  constructor(private appService: AppService) { }

  ngOnInit() {
    this.getTaluks();
  }

  talukupdateform = new FormGroup({
    taluk_nme: new FormControl(),
    taluk_id: new FormControl()
  })

  corvupdateform = new FormGroup({
    corv_nme: new FormControl('', [Validators.required]),
    talukId: new FormControl(),
    corv_id: new FormControl()
  })


  getTaluks() {
    this.appService.getTaluks().subscribe(
      data => {
        this.taluks = data;
        Swal.fire({
          icon: 'success',
          title: "Success",
          text: "Taluks are loaded sucessfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      })
  }

  getTaluk(talukName){
    console.log("method called");
    console.log(talukName);
    for (let taluk of this.taluks) {
      if(taluk.taluk_name == talukName){
        this.taluk = taluk;
        console.log(taluk);
      }
    }
  }

  addTaluk() {
    this.taluk = new Taluk();
    this.taluk.taluk_name = this.TalukNme.value;
    this.taluk.district_id = 1;
    console.log(this.taluk);
    this.appService.addTaluk(this.taluk, this.csrfToken).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: "Success",
          text:"Taluk is added sucessfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.talukupdateform.reset();
        (<any>$('#myTalukAddModal')).modal('hide');
        this.getTaluks();
      },
      error => {
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        //this.talukupdateform.reset();
        this.getToken();
      })
  }

  updTaluk(talukId) {
    for (let talk of this.taluks) {
      if (talukId == talk.taluk_id) {
        this.taluk = talk;
        break;
      }
    }
  }

  updateTaluk() {
    this.taluk = new Taluk();
    this.taluk.taluk_name = this.TalukNme.value;
    this.taluk.taluk_id = this.TalukId.value;
    this.taluk.district_id = 1;
    console.log(this.taluk);
    this.appService.updateTaluk(this.taluk, this.csrfToken).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: "Success",
          text:"Taluk is updated sucessfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.talukupdateform.reset();
        (<any>$('#myTalukUpdModal')).modal('hide');
        this.getTaluks();
      },
      error => {
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        //this.talukupdateform.reset();
        //this.getTaluks();
        this.getToken();
      })
  }

  deleteTaluk(talukId) {
    this.appService.deleteTaluk(talukId, this.csrfToken).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: "Success",
          text:"Taluk is deleted sucessfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.getTaluks();
      },
      error => {
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      })
  }

  addCorv() {
    this.corv = new Corv();
    this.corv.corv_name = this.CorvNme.value;
    this.corv.taluk_id = this.Taluk_Id.value;
    console.log(this.corv);

    this.appService.addCorv(this.corv, this.csrfToken).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: "Success",
          text:"City/Village is added sucessfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.corvupdateform.reset();
        (<any>$('#myCorvAddModal')).modal('hide');
        this.getTaluks();
      },
      error => {
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        //this.corvupdateform.reset();
        this.getToken();
      })

  }

  deleteCorv(corvId) {
    this.appService.deleteCorv(corvId, this.csrfToken).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: "Success",
          text:"City/Village is deleted sucessfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.getTaluks();
      },
      error => {
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      })
  }

  updCorv(corvId) {
    for (let talk of this.taluks) {
      for (let cor of talk.corv) {
        if (corvId == cor.corv_id) {
          this.corv = cor;
          this.talukId = talk.taluk_id;
          this.talukName = talk.taluk_name;
          break;
        }
      }

    }
  }

  updateCorv() {
    this.corv = new Corv();
    this.corv.corv_id = this.CorvId.value;
    this.corv.corv_name = this.CorvNme.value;
    this.corv.taluk_id = this.talukId;
    console.log(this.corv);

    this.appService.updateCorv(this.corv, this.csrfToken).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: "Success",
          text:"City/Village is updated sucessfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.corvupdateform.reset();
        (<any>$('#myCorvUpdModal')).modal('hide');
        this.getTaluks();
      },
      error => {
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        //this.corvupdateform.reset();
        //this.getTaluks();
        this.getToken();
      })
  }

  getToken() {
    this.appService.getToken().subscribe(
      data => {
        this.csrfToken = data;
        console.log(data);
      },
      error => {
        console.log(error);
        
      });
  }

  setTalukId(taluk_id) {
    this.talukId = taluk_id;
  }

  get TalukNme() {
    return this.talukupdateform.get('taluk_nme');
  }

  get TalukId() {
    return this.talukupdateform.get('taluk_id');
  }

  get CorvNme() {
    return this.corvupdateform.get('corv_nme');
  }

  get Taluk_Id() {
    return this.corvupdateform.get('talukId');
  }

  get CorvId() {
    return this.corvupdateform.get('corv_id');
  }


}
