import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalService } from '../local.service';
import { Order } from '../order';
import { ProductService } from '../product.service';
import { Servererror } from '../servererror';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  constructor(private productService: ProductService, private localService: LocalService) { }

  customer_id;
  orders: Observable<[Order]>;
  serverError: Servererror = new Servererror();
  previous = false;
  firstResult = 0;
  maxResult = 4;
  breakpoint = 2;
  public screenWidth: any;
  public orderStatus = ["ORDERED", "DISPATCHED", "DELIVERED"];

  ngOnInit() {

    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 700) {
      this.breakpoint = 1;
    }

    this.firstResult = 0;
    this.maxResult = 4;
    if (this.localService.getData('customerId') != null) {
      this.customer_id = this.localService.getData('customerId');
    }

    if (this.customer_id != null) {
      this.getOrderss();
    }
  }

  getOrderss() {
    this.productService.getOrdersByCustId(this.customer_id, this.firstResult, this.maxResult).subscribe(data => {
      this.orders = data;
    }, error => {
      this.serverError = error.error;
      Swal.fire({
        icon: 'error',
        title: this.serverError.error,
        text: this.serverError.message,
        iconColor: "#892205",
        color: "#2b617d",
        confirmButtonColor: "#2b617d",
        showConfirmButton: true,
        background: "#e5e6e6ed",
      });
      if (this.firstResult !== 0) {
        this.firstResult = this.firstResult - this.maxResult;
      }
      if (this.firstResult == 0) {
        this.previous = false;
      }
    })

  }

  nextOrders() {
    this.firstResult = this.firstResult + this.maxResult;
    this.previous = true;
    this.getOrderss();
  }

  previousOrders() {
    this.firstResult = this.firstResult - this.maxResult;
    if (this.firstResult == 0) {
      this.previous = false;
    }
    this.getOrderss();
  }

  getColor(status) {
    switch (status) {
      case 'DISPATCHED':
        return 'blue';
      case 'DELIVERED':
        return 'green';
      case 'ORDERED':
        return '#0793ba';  
    }
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 700) ? 1 : 2;
  }


}
