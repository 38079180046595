import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Category } from '../category';
import { Subcategory } from '../subcategory';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Servererror } from '../servererror';
import { AppService } from '../app.service';
import { Csrftoken } from '../csrftoken';

@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.css']
})
export class SubcategoryComponent implements OnInit {

  categories: Array<Category> = [];
  deleteMessage = false;
  subCategory = new Subcategory();
  category = new Category();
  category_id;
  serverError: Servererror = new Servererror();
  url: any;
  csrfToken : Csrftoken = new Csrftoken();

  constructor(private appService: AppService) { }

  ngOnInit() {
    this.getSubCategories();
    this.getToken();
  }

  subcategoryupdateform = new FormGroup({
    subcategory_id: new FormControl(),
    subcategory_name: new FormControl('', [Validators.required]),
    total_products: new FormControl()
  })

  subcategoryaddform = new FormGroup({
    subcategory_nme: new FormControl('', [Validators.required]),
    add_total_products: new FormControl(),
    category_id: new FormControl('', [Validators.required])
  })

  getSubCategories() {
    this.appService.getCategoryList().subscribe(
      data => {
        this.categories = data;
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
      });
  }

  deleteSubCategory(subcategory_id: number) {
    this.appService.deleteSubCategory(subcategory_id, this.csrfToken).subscribe(
      data => {
        console.log(data);
        Swal.fire({
          icon: 'success',
          title: "Success",
          text: "Subcategory deleted successfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        
        //window.location.reload();
        this.getSubCategories();
        this.getToken();
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      });
  }

  updateSubCategory(subcategory_id: number) {
    for (let categorie of this.categories) {
      console.log(categorie.category_name);
      for (let subcategory of categorie.subcategories) {
        console.log(subcategory.subcategory_name);
        if (subcategory_id == subcategory.subcategory_id) {
          this.subCategory = subcategory;
          this.category = categorie;
          this.category_id = categorie.category_id;
        }
      }
    }
  }

  updateSubCat(updsubcat) {
    this.subCategory = new Subcategory();
    this.subCategory.subcategory_id = this.SubCategoryId.value;
    this.subCategory.subcategory_name = this.SubCategoryName.value;
    this.subCategory.category_id = this.category_id;
    if (this.url != null) {
      var splitted = this.url.split("data:image/jpeg;base64,");
      this.subCategory.image = splitted[1];
    }

    console.log(this.subCategory);
    this.appService.updateSubCategory(this.subCategory, this.csrfToken).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: "Success",
          text: "Subcategory updated successfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        
        this.url = null;
        (<any>$('#myUpdModal')).modal('hide');
        this.getSubCategories();
        this.getToken();
        //window.location.reload();
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getSubCategories();
        this.url = null;
        this.getToken();
      });
  }

  addSubCat(addsubcat) {
    this.subCategory = new Subcategory();
    this.subCategory.subcategory_name = this.SubCategoryNme.value;
    this.subCategory.total_products = this.AddTotalProducts.value;
    this.subCategory.category_id = this.CategoryId.value;
    if (this.url != null) {
      var splitted = this.url.split("data:image/jpeg;base64,");
      this.subCategory.image = splitted[1];
    }

    this.appService.addSubCategory(this.subCategory, this.csrfToken).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: "Success",
          text: "Subcategory added successfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.url = null;
        (<any>$('#myAddModal')).modal('hide');
        //window.location.reload();
        this.getSubCategories();
        this.getToken();
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.url = null;
        this.getToken();
      });
  }

  setCategoryId(category_id: number) {
    this.category_id = category_id;
  }

  onselectFile(e) {
    if (e.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event: any) => {
        this.url = event.target.result;
        console.log(this.url);
      }
    }
  }

  getToken() {
    this.appService.getToken().subscribe(
      data => {
        this.csrfToken = data;
        console.log(data);
      },
      error => {
        console.log(error);
        
      });
  }

  get SubCategoryId() {
    return this.subcategoryupdateform.get('subcategory_id');
  }

  get SubCategoryName() {
    return this.subcategoryupdateform.get('subcategory_name');
  }

  get TotalProducts() {
    return this.subcategoryupdateform.get('total_products');
  }

  get SubCategoryNme() {
    return this.subcategoryaddform.get('subcategory_nme');
  }

  get AddTotalProducts() {
    return this.subcategoryaddform.get('add_total_products');
  }

  get CategoryId() {
    return this.subcategoryaddform.get('category_id');
  }
}
