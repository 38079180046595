import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalService } from './local.service';
import { Csrftoken } from './csrftoken';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  //private baseUrl = 'http://192.168.68.60:8080/api';
  //private baseUrl = 'http://localhost:8080/api';
  //private baseUrl = 'http://testdemo-env.eba-mfqf3qy3.us-east-1.elasticbeanstalk.com/api';
  private baseUrl = 'https://api.esshoppy.com/api';

  constructor(private http: HttpClient, private localService: LocalService) { }

  addProduct(value: any, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.post(`${this.baseUrl}/product/add-product`, value,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) 
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
      });
  }

  updateProduct(value: any, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.put(`${this.baseUrl}/product/update-product`, value,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) 
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
      });
  }

  getProducts(categoryId: string, subcategoryId: string, suprsubcategoryId: string,
    firstRes: any, maxRes: any, searchTxt: string): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("categoryId", categoryId);
    queryParams = queryParams.append("subcategoryId", subcategoryId);
    queryParams = queryParams.append("suprsubcategoryId", suprsubcategoryId);
    queryParams = queryParams.append("firstResult", firstRes);
    queryParams = queryParams.append("maxResult", maxRes);
    queryParams = queryParams.append("searchText", searchTxt);
    return this.http.get(`${this.baseUrl}` + '/product/view-products', { params: queryParams });
  }

  getProductsByDate(categoryId: string, subcategoryId: string, suprsubcategoryId: string,
    startDate: string, endDate: string, firstRes: any, maxRes: any): Observable<any> {
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("categoryId", categoryId);
    queryParams = queryParams.append("subcategoryId", subcategoryId);
    queryParams = queryParams.append("suprsubcategoryId", suprsubcategoryId);
    queryParams = queryParams.append("startDate", startDate);
    queryParams = queryParams.append("endDate", endDate);
    queryParams = queryParams.append("firstResult", firstRes);
    queryParams = queryParams.append("maxResult", maxRes);
    return this.http.get(`${this.baseUrl}/product/get-products-bydate`,
      { params: queryParams, headers: new HttpHeaders().set('Authorization', tokenBearer) });
  }

  getProductsByStatus(status: string, startDate: string, endDate: string): Observable<any> {
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("startDate", startDate);
    queryParams = queryParams.append("endDate", endDate);
    return this.http.get(`${this.baseUrl}/product/get-products-bystatus/${status}`,
      { params: queryParams, headers: new HttpHeaders().set('Authorization', tokenBearer) });
  }

  getRandomProducts(): Observable<any> {
    return this.http.get(`${this.baseUrl}` + '/product/random-products');
  }

  getProductsBySubCatId(subcategory_id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/product/get-products-bysubcat/${subcategory_id}`);
  }

  getProductsBySuprSubCatId(supersubcategory_id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/product/view-products/${supersubcategory_id}`);
  }

  getProduct(product_id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/product/view-product/${product_id}`);
  }

  deleteProduct(id: number, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.delete(`${this.baseUrl}/product/delete-product/${id}`,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) 
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
      });
  }

  orderProduct(order:object, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.post(`${this.baseUrl}/product/order-product`, order,
    { headers: new HttpHeaders().set('Authorization', tokenBearer) 
      .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
    });
  }

  getOrder(orderId: string, productId: string): Observable<any> {
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("orderId", orderId);
    queryParams = queryParams.append("productId", productId);
    return this.http.get(`${this.baseUrl}/product/get-order`,
      { params: queryParams, headers: new HttpHeaders().set('Authorization', tokenBearer) });
  }

  getOrdersByDate(startDate: string, endDate: string, firstRes: any, maxRes: any): Observable<any> {
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("startDate", startDate);
    queryParams = queryParams.append("endDate", endDate);
    queryParams = queryParams.append("firstResult", firstRes);
    queryParams = queryParams.append("maxResult", maxRes);
    return this.http.get(`${this.baseUrl}/product/get-orders-bydate`,
      { params: queryParams, headers: new HttpHeaders().set('Authorization', tokenBearer) });
  }

  updateOrder(order: any, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.put(`${this.baseUrl}/product/update-order`, order,
    { headers: new HttpHeaders().set('Authorization', tokenBearer) 
      .set('X-XSRF-TOKEN', csrf_token),
      withCredentials:true 
    });
  }

  getDeliveredOrdersByDate(startDate: string, endDate: string, firstRes: any, maxRes: any): Observable<any> {
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("startDate", startDate);
    queryParams = queryParams.append("endDate", endDate);
    queryParams = queryParams.append("firstResult", firstRes);
    queryParams = queryParams.append("maxResult", maxRes);
    return this.http.get(`${this.baseUrl}/product/get-deliveredorders-bydate`,
      { params: queryParams, headers: new HttpHeaders().set('Authorization', tokenBearer) });
  }

  getOrdersByCustId(customer_id: string, firstRes: any, maxRes: any): Observable<any> {
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("firstResult", firstRes);
    queryParams = queryParams.append("maxResult", maxRes);
    return this.http.get(`${this.baseUrl}/product/get-orders/${customer_id}`, 
    { params: queryParams , headers: new HttpHeaders().set('Authorization', tokenBearer)});
  }

  addToCart(cart: object, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.post(`${this.baseUrl}` + '/product/add-to-cart', cart,
    { headers: new HttpHeaders().set('Authorization', tokenBearer)
      .set('X-XSRF-TOKEN', csrf_token),
      withCredentials:true 
    });
  }

  getCartItemsByCustId(customer_id: string): Observable<any> {
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.get(`${this.baseUrl}/product/get-cart-items/${customer_id}`,
    { headers: new HttpHeaders().set('Authorization', tokenBearer)});
  }

  removeCartItems(product_id: string, customer_id: string, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.delete(`${this.baseUrl}/product/remove-cart-item/${customer_id}/${product_id}`,
    { headers: new HttpHeaders().set('Authorization', tokenBearer)
      .set('X-XSRF-TOKEN', csrf_token),
      withCredentials:true 
    });
  }

  getDispatchedOrdersByDate(startDate: string, endDate: string, firstRes: any, maxRes: any): Observable<any> {
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("startDate", startDate);
    queryParams = queryParams.append("endDate", endDate);
    queryParams = queryParams.append("firstResult", firstRes);
    queryParams = queryParams.append("maxResult", maxRes);
    return this.http.get(`${this.baseUrl}/product/get-dispatchedorders-bydate`,{ params: queryParams ,
      headers: new HttpHeaders().set('Authorization', tokenBearer)});
  }

} 
