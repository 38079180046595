import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../customer.service';
import { Customerdetails } from '../customerdetails';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Servererror } from '../servererror';
import { Address } from '../address';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { SignupComponent } from '../signup/signup.component';

@Component({
  selector: 'app-managecustomers',
  templateUrl: './managecustomers.component.html',
  styleUrls: ['./managecustomers.component.css']
})
export class ManagecustomersComponent implements OnInit {

  constructor(private customerService: CustomerService, private dialog: MatDialog) { }

  customers: Array<Customerdetails> = [];
  customerDetails: Customerdetails = new Customerdetails();
  serverError: Servererror = new Servererror();
  address = new Address();
  searchText = '';
  firstResult = 0;
  maxResult = 20;
  previous = false;
  key: string = 'customer_id';
  reverse: boolean = false;
  public screenWidth: any;

  ngOnInit() {
    this.previous = false;
    this.getCustomers(this.firstResult, this.maxResult);
  }

  searchCust = new FormGroup({
    phone_num: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)])
  })

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  nextCustomers() {
    this.firstResult = this.firstResult + this.maxResult;
    this.previous = true;
    this.getCustomers(this.firstResult, this.maxResult);
  }

  previousCustomers() {
    this.firstResult = this.firstResult - this.maxResult;
    if (this.firstResult == 0) {
      this.previous = false;
    }
    this.getCustomers(this.firstResult, this.maxResult);
  }

  searchCustomer(phoneNum) {
    this.customerService.getCustomerByPhNum(this.PhoneNum.value).subscribe(
      data => {
        console.log(data);
        this.customerDetails = data;
        this.customers = [];
        this.customers.push(this.customerDetails);

        Swal.fire({
          icon: 'success',
          title: "Success",
          text: "Customer is loaded successfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: "Customer is failed to load",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
      });
  }

  getCustomers(fr, mx) {
    this.customerService.getCustomers(fr, mx).subscribe(
      data => {
        console.log(data);
        this.customers = data;
        Swal.fire({
          icon: 'success',
          title: "Success",
          text:"Customers are loaded successfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        if(this.firstResult !== 0){
          this.firstResult = this.firstResult - this.maxResult;
        }
        if (this.firstResult == 0) {
          this.previous = false;
        }
        console.log(this.firstResult);
        Swal.fire({
          icon: 'error',
          title: "Customers are failed to load",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
      });
  }

  get PhoneNum() {
    return this.searchCust.get('phone_num');
  }

  handleSignupAction() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50vw";
    this.screenWidth = window.innerWidth;
    dialogConfig.panelClass = "custom-modalbox";
    if(this.screenWidth <= 700){
      dialogConfig.width = "100vw";
    }
    this.dialog.open(SignupComponent, dialogConfig);
  }



}
