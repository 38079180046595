import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Category } from '../category';
import { ProductService } from '../product.service';
import { Servererror } from '../servererror';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AppService } from '../app.service';
import { Csrftoken } from '../csrftoken';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  name: any;
  sub: any;
  categories: Observable<Category[]>;
  category = new Category();
  isupdated = false;
  deleteMessage = false;
  isadded = false;
  serverError: Servererror = new Servererror();
  csrfToken : Csrftoken = new Csrftoken();

  constructor(private route: ActivatedRoute, private productService: ProductService,
    private appService: AppService) { }

  ngOnInit() {
    this.isupdated = false;
    this.isadded = false;
    this.sub = this.route.params.subscribe(params => {
      this.name = params['name'];
    });
    //this.getToken();
    this.getCategories();
  }

  categoryupdateform = new FormGroup({
    category_id: new FormControl(),
    category_name: new FormControl(),
    total_products: new FormControl(),
    category_nme: new FormControl()
  })

  getCategories() {
    this.appService.getCategoryList().subscribe(
      data => {
        this.categories = data;
        this.getToken();
      }, error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      });
  }

  updateCategory(id: number) {
    this.appService.getCategory(id).subscribe(
      data => {
        this.category = data
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
      });
  }

  updateCat(updcat) {
    this.category = new Category();
    this.category.category_id = this.CategoryId.value;
    this.category.category_name = this.CategoryName.value;

    this.appService.updateCategory(this.category, this.csrfToken).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: "Success",
          text: "Category updated successfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        (<any>$('#myUpdModal')).modal('hide');
        this.getCategories();
        //window.location.reload();
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      });
  }

  addCat(addcat) {
    this.getToken();
    this.category = new Category();
    this.category.category_name = this.CategoryNme.value;
    this.category.total_products = this.TotalProducts.value;

    this.appService.addCategory(this.category, this.csrfToken).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: "Success",
          text: "Category added successfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        (<any>$('#myAddModal')).modal('hide');
        this.getCategories();
        //window.location.reload();
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      });
  }

  deleteCategory(id: number) {
    this.appService.deleteCategory(id, this.csrfToken)
      .subscribe(
        data => {
          console.log(data);
          Swal.fire({
            icon: 'success',
            title: "Success",
            text: "Category deleted successfully",
            iconColor: "#2b617d",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            timer: 1500,
            timerProgressBar: true,
            background: "#e5e6e6ed",
          });
          this.getCategories();
          //window.location.reload();
        },
        error => {
          console.log(error);
          this.serverError = error.error;
          Swal.fire({
            title: this.serverError.error,
            text: this.serverError.message,
            icon: 'error',
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
          this.getToken();
        });
  }

  getToken() {
    this.appService.getToken().subscribe(
      data => {
        this.csrfToken = data;
        console.log(data);
      },
      error => {
        console.log(error);
        
      });
  }

  get CategoryId() {
    return this.categoryupdateform.get('category_id');
  }

  get CategoryName() {
    return this.categoryupdateform.get('category_name');
  }

  get TotalProducts() {
    return this.categoryupdateform.get('total_products');
  }

  get CategoryNme() {
    return this.categoryupdateform.get('category_nme');
  }

}
