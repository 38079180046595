import { Address } from "./address";

export class Customerdetails {
    customer_id:number;
    email:string;
    phone_num:number;
    password:string;
    gender:string;
    full_name=String;
    addresses: Address[];
}
