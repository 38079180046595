import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import 'rxjs/add/operator/do';
import { CacheService } from './cache.service';
import { Observable } from 'rxjs';
import { of } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class CacheInterceptor implements HttpInterceptor {

    constructor(private cacheService: CacheService) { }

    private cache: Map<String, [Date, HttpResponse<any>]> = new Map();

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if(req.urlWithParams.includes("update-category") || req.urlWithParams.includes("add-category")
        || req.urlWithParams.includes("delete-category") || req.urlWithParams.includes("update-subcategory") 
        || req.urlWithParams.includes("add-subcategory") || req.urlWithParams.includes("delete-subcategory")
        || req.urlWithParams.includes("add-supersubcategory") || req.urlWithParams.includes("update-supersubcategory") 
        || req.urlWithParams.includes("delete-supersubcategory")){
            this.cache.clear();
            console.log("Response deleted from cache");
        }

        if (req.method !== "GET" ) {
            return next.handle(req)
        }

        if (req.method == "GET" && (req.urlWithParams.includes("view-products") 
           // || req.urlWithParams.includes("category-list") || req.urlWithParams.includes("subcategory-list")
            )) {
            //const cachedResponse: HttpResponse<any> = this.cache.get(req.urlWithParams);
            const tuple = this.cache.get(req.urlWithParams);

            if (tuple) {
                const expiresIn = tuple[0];
                const cachedResponse = tuple[1];
                const now = new Date();


                if (expiresIn && expiresIn.getTime() > now.getTime() && cachedResponse) {
                    console.log("Response from cache");
                    return of(cachedResponse.clone());
                } else if (expiresIn && expiresIn.getTime() < now.getTime() && cachedResponse) {
                    this.cache.delete(req.urlWithParams);
                    console.log("Response deleted from cache because of time expired");
                }
            }
        }

    
        return next.handle(req).do(event => {
            if (event instanceof HttpResponse && (req.urlWithParams.includes("view-products") || 
            req.urlWithParams.includes("category-list") || req.urlWithParams.includes("subcategory-list"))) {
                const expiresIn = new Date();
                expiresIn.setMinutes(expiresIn.getMinutes() + 2);
                this.cache.set(req.urlWithParams, [expiresIn, event.clone()]);
                console.log(this.cache.set(req.urlWithParams, [expiresIn, event.clone()]));
                console.log("Catched Response from server for view products")
            }
        });
    }

}
