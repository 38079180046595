import { Component, OnInit } from '@angular/core';
import { Address } from '../address';
import { Cart } from '../cart';
import { Product } from '../product';
import { ProductService } from '../product.service';
import { Servererror } from '../servererror';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LocalService } from '../local.service';
import { CustomerService } from '../customer.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { LoginComponent } from '../login/login.component';
import { Observable } from 'rxjs';
import { AddressComponent } from '../address/address.component';
import { Order } from '../order';
import { Customerdetails } from '../customerdetails';
import { Csrftoken } from '../csrftoken';
import { AppService } from '../app.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  constructor(private productservice: ProductService, private customerService: CustomerService,
   private localService: LocalService, private dialog: MatDialog, private appService: AppService) { }

  product_id;
  product: Product = new Product();
  customerId: string;
  serverError: Servererror = new Servererror();
  addresses: Array<Address> = [];
  addrAvailable = false;
  cart: Cart = new Cart();
  public screenWidth: any;
  categoryId;
  suprSubCategoryId;
  firstResult = 0;
  maxResult = 4;
  previous = false;
  products: Observable<Product[]>;
  breakpoint = 4;
  order: Order = new Order();
  customer: Customerdetails = new Customerdetails();
  orderproduct: Product = new Product();
  csrfToken : Csrftoken = new Csrftoken();


  ngOnInit() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 700) {
      this.breakpoint = 2;
    }

    this.addrAvailable = false;

    this.product_id = this.localService.getData('productId');

    if (this.product_id != null) {
      this.getProduct(this.product_id);
    }

  }

  getProduct(productId: number) {
    this.addrAvailable = false;
    this.productservice.getProduct(productId).subscribe(data => {
      this.product = data;
      this.categoryId = this.product.cat_id;
      this.suprSubCategoryId = this.product.supersubcategory_id;
      if (this.product.cat_id && this.suprSubCategoryId != null) {
        this.getRelatedProducts(this.categoryId, 0, this.suprSubCategoryId, this.firstResult, this.maxResult, "");
      }

      this.getToken();
    }, error => {
      this.serverError = error.error;
      Swal.fire({
        icon: 'error',
        title: this.serverError.error,
        text: this.serverError.message,
        iconColor: "#892205",
        color: "#2b617d",
        confirmButtonColor: "#2b617d",
        showConfirmButton: true,
        timer: 1500,
        timerProgressBar: true,
        background: "#e5e6e6ed",
      });
    })
  }


  checkEligabality(product_id: number) {

    if (this.localService.getData('customerId') != null) {
      this.customerId = this.localService.getData('customerId');
    }

    if (this.customerId == null) {
      this.login();
    }

    else if (this.customerId != null) {

      this.customerService.getAddressByCustId(this.customerId).subscribe(
        data => {
          this.addresses = data;

          if (this.addresses.length != 0) {
            this.addrAvailable = true;
          }
          else if (this.addresses.length == 0) {
            this.addAddress();
          }
        },
        error => {
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: "Address not available/Fail to fetch address",
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
          this.addAddress();
        })
    }
  }


  orderProduct(product_id: number) {
    if (this.localService.getData('customerId') != null) {
      this.customerId = this.localService.getData('customerId');
    }

    this.order = new Order();
    this.customer.customer_id = Number(this.customerId)
    //this.order.customer_id = Number(this.customerId);
    this.order.customer = this.customer;
    //this.order.product_id = Number(product_id);
    this.orderproduct = new Product();
    this.orderproduct.product_id = Number(product_id);
    this.order.product = this.orderproduct;

    this.productservice.orderProduct(this.order, this.csrfToken)
      .subscribe(data => {
        this.serverError = data;
        Swal.fire({
          icon: 'success',
          title: "Ordered successfully",
          text: this.serverError.message,
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
        
      },
        error => {
          console.log(error);
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: "Failed to Order",
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
          this.getToken();
        });
  }

  addTocart(product_id: string) {

    if (this.localService.getData('customerId') != null) {
      this.customerId = this.localService.getData('customerId');
    }

    if (this.customerId == null) {
      this.login();
    }

    else if (this.customerId != null) {
      this.cart = new Cart();
      this.cart.customer_id = this.customerId;
      this.cart.product_id = product_id;

      this.productservice.addToCart(this.cart, this.csrfToken).subscribe(
        data => {
        Swal.fire({
          icon: 'success',
          title:'Success',
          text: "Added to Cart successfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
          
        });
        this.getToken();
      },
        error => {
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: this.serverError.error,
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
          this.getToken();
        })
    }
  }

  login() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50vw";
    this.screenWidth = window.innerWidth;
    dialogConfig.panelClass = "custom-modalbox";
    if (this.screenWidth <= 700) {
      dialogConfig.width = "100vw";
    }
    this.dialog.open(LoginComponent, dialogConfig);
  }

  addAddress() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50vw";
    dialogConfig.data = "isProduct";
    this.screenWidth = window.innerWidth;
    dialogConfig.panelClass = "custom-modalbox";
    if (this.screenWidth <= 700) {
      dialogConfig.width = "100vw";
    }
    this.dialog.open(AddressComponent, dialogConfig);
  }

  getRelatedProducts(categoryId, subCategoryId, suprSubcategoryId, fr, mx, searchTxt) {
    this.productservice.getProducts(categoryId, subCategoryId, suprSubcategoryId, fr, mx, searchTxt).subscribe(
      data => {
        this.products = data;
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        if (this.firstResult !== 0) {
          this.firstResult = this.firstResult - this.maxResult;
        }
        if (this.firstResult == 0) {
          this.previous = false;
        }
        Swal.fire({
          icon: 'error',
          title: "Products are failed to load",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
      })
  }

  nextProducts() {
    this.firstResult = this.firstResult + this.maxResult;
    this.previous = true;
    this.getRelatedProducts(this.categoryId, 0, this.suprSubCategoryId,
      this.firstResult, this.maxResult, "");
  }

  previousProducts() {
    this.firstResult = this.firstResult - this.maxResult;
    if (this.firstResult == 0) {
      this.previous = false;
    }
    this.getRelatedProducts(this.categoryId, 0, this.suprSubCategoryId,
      this.firstResult, this.maxResult, "");
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 700) ? 2 : 4;
  }

  getToken() {
    this.appService.getToken().subscribe(
      data => {
        this.csrfToken = data;
        console.log(data);
      },
      error => {
        console.log(error);
        
      });
  }


}
