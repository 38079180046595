import { Component, OnInit } from '@angular/core';
import { ProductService } from '../product.service';
import { Product } from '../product';
import { Servererror } from '../servererror';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormControl, FormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';
import { AppService } from '../app.service';
import { Category } from '../category';
import { Subcategory } from '../subcategory';
import { Supersubcategory } from '../supersubcategory';

@Component({
  selector: 'app-allproducts',
  templateUrl: './allproducts.component.html',
  styleUrls: ['./allproducts.component.css']
})
export class AllproductsComponent implements OnInit {

  constructor(private productservice: ProductService, private appService: AppService) { }

  startDate: string = "";
  endDate: string = "";
  products: Array<Product> = [];
  serverError: Servererror = new Servererror();
  categories: Array<Category> = [];
  category_id: number = 0;
  subcategories: Array<Subcategory> = [];
  subcategory = new Subcategory();
  subcategory_id: number = 0;
  supersubcategory_id: number = 0;
  supersubcategory = new Supersubcategory();
  previous = false;
  firstResult = 0;
  maxResult = 8;
  productId;
  product: Product = new Product();

  ngOnInit() {
    this.previous = false;
    this.getCategories();
  }

  productsearchform = new FormGroup({
    start_date: new FormControl(),
    end_date: new FormControl()
  })

  getCategories() {
    this.appService.getCategoryList().subscribe(data => {
      this.categories = data;

    }, error => {
      this.serverError = error.error;
      Swal.fire({
        icon: 'error',
        title: this.serverError.error,
        text: this.serverError.message
      });
    })
  }

  getsubcategory(category_id: number) {
    this.category_id = category_id;
    this.subcategory_id = 0;
    if (this.category_id != null && this.category_id !== 0) {
      this.appService.getSubcategoryList(category_id).subscribe(data => {
        this.subcategories = data;
      })
    }
  }

  getsupersubcategory(subcategoryId: number) {
    this.subcategory_id = subcategoryId;
    this.supersubcategory_id = 0;
    for (let subcategorie of this.subcategories) {
      if (subcategorie.subcategory_id == subcategoryId) {
        this.subcategory = subcategorie;
      }
    }
  }

  setsupersubcategoryid(supersubcategoryId: number) {
    this.supersubcategory_id = supersubcategoryId;
  }

  searchProduct() {
    this.getProductsByDate();
  }

  getProductsByDate() {
    const format = 'dd-MM-yyyy';
    const locale = 'en-US';
    if (this.StartDate.value != null && this.StartDate.value !== "") {
      this.startDate = formatDate(this.StartDate.value, format, locale);
    }
    if (this.EndDate.value != null && this.EndDate.value !== "") {
      this.endDate = formatDate(this.EndDate.value, format, locale);
    }

    this.productservice.getProductsByDate(String(this.category_id), String(this.subcategory_id),
      String(this.supersubcategory_id), this.startDate, this.endDate, this.firstResult, this.maxResult).subscribe(
        data => {
          this.products = data;
          this.startDate = "";
          this.endDate = "";
        },
        error => {
          console.log(error);
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: "Products are failed to load",
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            timer: 1500,
            timerProgressBar: true,
            background: "#e5e6e6ed",
          });
          this.products = null;
          this.startDate = "";
          this.endDate = "";
          if (this.firstResult !== 0) {
            this.firstResult = this.firstResult - this.maxResult;
          }
          if (this.firstResult == 0) {
            this.previous = false;
          }
        })
  }

  searchProductById() {
    this.productservice.getProduct(this.productId).subscribe(data => {
      this.product = data;
      this.products = [];
      this.products.push(this.product);
    }, error => {
      this.serverError = error.error;
      Swal.fire({
        icon: 'error',
        title: this.serverError.error,
        text: this.serverError.message,
        iconColor: "#892205",
        color: "#2b617d",
        confirmButtonColor: "#2b617d",
        showConfirmButton: true,
        timer: 1500,
        timerProgressBar: true,
        background: "#e5e6e6ed",
      });
    })
  }

  nextProducts() {
    this.firstResult = this.firstResult + this.maxResult;
    this.previous = true;
    this.getProductsByDate();
  }

  previousProducts() {
    this.firstResult = this.firstResult - this.maxResult;
    if (this.firstResult == 0) {
      this.previous = false;
    }
    this.getProductsByDate();
  }

  getColor(status) {
    switch (status) {
      case 'OUTOFSTOCK':
        return 'red';
      case 'INSTOCK':
        return 'green';
    }
  }

  get StartDate() {
    return this.productsearchform.get('start_date');
  }

  get EndDate() {
    return this.productsearchform.get('end_date');
  }


}
