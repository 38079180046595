import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Product } from '../product';
import { ProductService } from '../product.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LocalService } from '../local.service';
import { Servererror } from '../servererror';
import { AppService } from '../app.service';
import { Csrftoken } from '../csrftoken';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  constructor(private productService: ProductService, private router: Router,
    private localService: LocalService, private appService: AppService) { }

  customer_id;
  cartItems: Observable<[Product]>;
  serverError: Servererror = new Servererror();
  breakpoint = 2;
  public screenWidth: any;
  csrfToken : Csrftoken = new Csrftoken();

  ngOnInit() {

    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 700) {
      this.breakpoint = 1;
    }

    if (this.localService.getData('customerId') != null) {
      this.customer_id = this.localService.getData('customerId');
    }

    if (this.customer_id != null) {
      this.getCartItems(this.customer_id);
    }

  }

  getCartItems(customerId: string) {
    this.productService.getCartItemsByCustId(customerId).subscribe(
      data => {
        this.cartItems = data;
        this.getToken();
      },
      error => {
        this.serverError = error.error;
        this.cartItems = null;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
      })
  }

  swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger"
    },
    buttonsStyling: false
  });

  orderProduct(product_id: string, status: string) {
    if (status == "OUTOFSTOCK") {
      Swal.fire({
        icon: 'error',
        title: "Error",
        text: "Can't order product is out of stock",
        iconColor: "#892205",
        color: "#032127",
        confirmButtonColor: "#032127",
        showConfirmButton: true,
        timer: 1500,
        timerProgressBar: true,
        background: "#e5e6e6ed",
      });
    } else if (status == "INSTOCK") {
      this.localService.saveData('productId', product_id.toString());
      this.router.navigate(['product']);
    }
  }

  getColor(status) {
    switch (status) {
      case 'OUTOFSTOCK':
        return 'red';
      case 'INSTOCK':
        return 'green';
    }
  }

  removeProduct(product_id: string) {
    this.productService.removeCartItems(product_id, this.customer_id, this.csrfToken).subscribe(data => {

      Swal.fire({
        icon: 'success',
        title: "Success",
        text: "Item is removed from cart successfully",
        iconColor: "#032c0f",
        color: "#032127",
        confirmButtonColor: "#032127",
        showConfirmButton: true,
        timer: 1500,
        timerProgressBar: true,
        background: "#e5e6e6ed",
      });
      if (this.customer_id != null) {
        this.getCartItems(this.customer_id);
      }
    },
      error => {
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#032127",
          confirmButtonColor: "#032127",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
      })
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 700) ? 1 : 2;
  }

  getToken() {
    this.appService.getToken().subscribe(
      data => {
        this.csrfToken = data;
        console.log(data);
      },
      error => {
        console.log(error);
        
      });
  }

}
