import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from '../customer.service';
import { Router } from '@angular/router';
import { LoginCredentials } from '../login-credentials';
import { Customerdetails } from '../customerdetails';
import { Servererror } from '../servererror';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Authenticateresponse } from '../authenticateresponse';
import { User } from '../user';
import { LocalService } from '../local.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { AdminService } from '../admin.service';
import { SignupComponent } from '../signup/signup.component';
import { Csrftoken } from '../csrftoken';
import { HttpXsrfTokenExtractor } from '@angular/common/http';
import { AppService } from '../app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private customerService: CustomerService, private adminService: AdminService,
    private router: Router, private localService: LocalService, private dialog: MatDialog, private tokenExtractor: HttpXsrfTokenExtractor,
  private appService: AppService) { }

  password;
  show = false;
  loginCredentials: LoginCredentials = new LoginCredentials();
  customerDetails: Customerdetails = new Customerdetails();
  serverError: Servererror = new Servererror();
  product_id;
  authRes: Authenticateresponse = new Authenticateresponse();
  user: User = new User();
  public screenWidth: any;
  csrfToken : Csrftoken = new Csrftoken();

  ngOnInit() {
    this.getToken();
    this.password = 'password';
  }

  customerloginform = new FormGroup({
    phone: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.pattern("^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{7,15}$")]),
  });


  login() {
    this.loginCredentials = new LoginCredentials();
    this.loginCredentials.phonenum = this.Phone.value;
    this.loginCredentials.password = this.Password.value;
    if(this.Phone.value && this.Password.value){
      if (this.loginCredentials.phonenum.startsWith("Admin")) {
        const arry = this.loginCredentials.phonenum.split("/");
        this.loginCredentials.phonenum = null;
        let username = arry[1];
        this.loginCredentials.username = username;
        this.adminLogin();
      } else if (this.loginCredentials.phonenum.startsWith("User")) {
        const arry = this.loginCredentials.phonenum.split("/");
        this.loginCredentials.phonenum = null;
        let phonenum = arry[1];
        this.loginCredentials.phonenum = phonenum;
        this.userLogin();
      } else {
        this.customerLogin();
      }
    }
    

  }

  customerLogin() {
    //this.getToken();
    this.customerService.loginCustomer(this.loginCredentials, this.csrfToken).subscribe(
      data => {
        console.log(data)
        this.authRes = new Authenticateresponse(); 
        this.authRes = data;
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: "LogIn is sucessfull",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });

        this.localService.saveData('token', this.authRes.jwt);

        this.localService.saveData('customerId', this.authRes.id.toString());

        this.customerloginform.reset();

        this.product_id = this.localService.getData('productId');

        if (this.product_id == null) {
          this.router.navigate(['view-products']).then(() => {
            window.location.reload();
          });
        }

        if (this.product_id != null) {
          this.router.navigate(['product']).then(() => {
            window.location.reload();
          });
        }

      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: "LogIn is failed",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      });
  }

  adminLogin() {
    //this.getToken();
    this.adminService.adminAuth(this.loginCredentials, this.csrfToken)
      .subscribe
      (data => {
        console.log(data)
        this.authRes = new Authenticateresponse(); 
        this.authRes = data;
        Swal.fire({
          icon: 'success',
          title: "LogIn is sucessfull",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });

        this.localService.saveData('token', this.authRes.jwt);

        this.customerloginform.reset();
        this.router.navigate(['view-products']).then(() => {
          window.location.reload();
        });

      },
        error => {
          console.log(error);
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: "LogIn is failed",
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
        });
  }


  userLogin() {
    //this.getToken();
    this.adminService.userLogin(this.loginCredentials, this.csrfToken).subscribe
      (data => {
        console.log(data)
        this.authRes = new Authenticateresponse(); 
        this.authRes = data;
        Swal.fire({
          icon: 'success',
          title: "LogIn is sucessfull",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });

        this.localService.saveData('token', this.authRes.jwt);
        this.localService.saveData('userId', this.authRes.id.toString());

        this.customerloginform.reset();
        this.router.navigate(['dispatched-orders']).then(() => {
          window.location.reload();
        });

      },
        error => {
          console.log(error);
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: "LogIn is failed",
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
        });
  }

  getToken() {
    this.appService.getToken().subscribe(
      data => {
        this.csrfToken = data;
        console.log(data);
      },
      error => {
        console.log(error);
        
      });
  }


  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

  signUp() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50vw";
    this.screenWidth = window.innerWidth;
    dialogConfig.panelClass = "custom-modalbox";
    if (this.screenWidth <= 700) {
      dialogConfig.width = "100vw";
    }
    this.dialog.open(SignupComponent, dialogConfig);
  }

  forgotPassword() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50vw";
    this.screenWidth = window.innerWidth;
    dialogConfig.panelClass = "custom-modalbox";
    if (this.screenWidth <= 700) {
      dialogConfig.width = "100vw";
    }
    this.dialog.open(ForgotPasswordComponent, dialogConfig);
  }

  get Phone() {
    return this.customerloginform.get('phone');
  }

  get Password() {
    return this.customerloginform.get('password');
  }

}
