import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Address } from '../address';
import { Corv } from '../corv';
import { CustomerService } from '../customer.service';
import { Customerdetails } from '../customerdetails';
import { PhoneVerify } from '../phone-verify';
import { Servererror } from '../servererror';
import { Taluk } from '../taluk';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LocalService } from '../local.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { AppService } from '../app.service';
import { AddressComponent } from '../address/address.component';
import { Custphone } from '../custphone';
import { Csrftoken } from '../csrftoken';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  customer_id: string;
  customerDetails: Customerdetails = new Customerdetails();
  custphone: Custphone = new Custphone();
  addresses: Address = new Address();
  taluks: Observable<Taluk[]>;
  talukname;
  corvs: Observable<Corv[]>;
  phoneVerify: PhoneVerify = new PhoneVerify();
  corvname = "none";
  isTalukChanged = false;

  serverError: Servererror = new Servererror();
  old_phone_num: number;
  public screenWidth: any;
  count = 0;
  csrfToken: Csrftoken = new Csrftoken();

  constructor(private customerService: CustomerService, private appService: AppService, private router: Router,
    private localService: LocalService, private dialog: MatDialog) { }

  ngOnInit() {
    this.count = 0;
    this.getTaluks();
    this.getToken();

    if (this.localService.getData('customerId') != null) {
      this.customer_id = this.localService.getData('customerId');
    }

    if (this.customer_id != null) {
      this.getCustomer();
    }
  }

  custupdateform = new FormGroup({
    full_name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]),
    email: new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]),
    phone_num: new FormControl(),
    gender: new FormControl(),
    landmark: new FormControl(),
    corv: new FormControl('', [Validators.required]),
    pincode: new FormControl(),
    taluk: new FormControl(),
    district: new FormControl(),
    state: new FormControl(),
    addressId: new FormControl(),
    selectedTaluk: new FormControl(),
    otp: new FormControl(),
    oldTaluk: new FormControl(),
  });

  phoneupdateform = new FormGroup({
    phonenum: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)])
  });


  getTaluks() {
    this.appService.getTaluksById(1).subscribe(
      data => {
        this.taluks = data;
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: "Taluks are failed to load",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
      });
  }

  getCustomer() {
    this.customerService.getCustomer(this.customer_id).subscribe(
      data => {
        this.customerDetails = data;
        this.old_phone_num = this.customerDetails.phone_num;
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: "unable to load profile information",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
      });
  }

  getcorv(selectedTaluk: Taluk) {
    this.talukname = selectedTaluk.taluk_name;
    console.log(this.talukname);
    this.appService.getCorvs(selectedTaluk.taluk_id).subscribe(data => {
      this.corvs = data;
      this.isTalukChanged = true;
      this.corvname = "none";
    },
    error => {
      console.log(error);
      this.serverError = error.error;
      Swal.fire({
        icon: 'error',
        title: "City/Villages are failed to load",
        text: this.serverError.message,
        iconColor: "#892205",
        color: "#2b617d",
        confirmButtonColor: "#2b617d",
        showConfirmButton: true,
        timer: 1500,
        timerProgressBar: true,
        background: "#e5e6e6ed",
      });
    })
  }


  updateCust(updcust) {
    //this.customerDetails = new Customerdetails();
    
    this.customerDetails.full_name = this.FullName.value;
    this.customerDetails.email = this.Email.value;
    
    //this.customerDetails.phone_num = this.PhoneNum.value;
    this.customerDetails.gender = this.Gender.value;

    let addresses: Array<Address> = [];
    let address = new Address();

    address.address_id = this.AddressId.value;
    address.state = this.State.value;
    address.district = this.District.value;
    address.taluk = this.Taluk.value;

    if (this.talukname != null) {
      address.taluk = this.talukname;
    }

    address.pincode = this.Pincode.value;
    address.corv = this.Corv.value;
    if (this.isTalukChanged) {
      address.corv = this.corvname;
    }
    address.landmark = this.Landmark.value;

    if (address != null) {
      addresses.push(address);
      this.customerDetails.addresses = addresses;
    }
    //if (this.old_phone_num == this.customerDetails.phone_num) {
      this.save();
    //} else if (this.old_phone_num != this.customerDetails.phone_num) {
      //this.verifyPhone();
    //}

  }

  verifyPhone() {
    this.phoneVerify = new PhoneVerify();
    this.phoneVerify.phone_no = this.PhoneNumber.value;
    this.customerService.verifyPhone(this.phoneVerify, this.csrfToken).subscribe(
      data => {
        this.getToken();
        console.log(data)
        this.count = 0;
        this.enterOtp();
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: "Phone number verification is failed",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      });
  }

  verifyOtp(otp: string) {
    this.phoneVerify.otp = otp;
    this.customerService.verifyOtp(this.phoneVerify, this.csrfToken).subscribe(
      data => {
        this.getToken();
        console.log(data)
        this.updatePhone(otp);
      },
      error => {
        console.log(error);
        console.log(this.count);
        this.count++;
        console.log(this.count);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: "OTP verification is failed",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.getToken();

        if (this.count<3) {
          this.enterOtp()
        } else {
          Swal.fire({
            icon: 'error',
            title: "OTP verification is failed",
            text: "you have exceeded the limit please try again after some time",
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
        }

      });
  }

  async enterOtp() {
    const { value: otp } = await Swal.fire({
      title: 'Enter your OTP',
      input: 'password',
      inputLabel: 'OTP',
      inputPlaceholder: 'Enter your OTP',
      confirmButtonColor: "#2b617d",
      showConfirmButton: true,
      inputAttributes: {
        maxlength: 10,
        autocapitalize: 'off',
        autocorrect: 'off'
      }
    })

    if (otp) {
      this.verifyOtp(otp);
    } else {
      Swal.fire({
        icon: 'error',
        title: "OTP verification is failed",
        text: "please enter OTP and submit",
        iconColor: "#892205",
        color: "#2b617d",
        confirmButtonColor: "#2b617d",
        showConfirmButton: true,
        background: "#e5e6e6ed",
      });
      this.enterOtp();
    }
  }

  save() {
    this.customerService.updateCustomer(this.customerDetails, this.csrfToken).subscribe(
      data => {
        console.log(data);
        Swal.fire({
          icon: 'success',
          title: "Success",
          text: "update is successfull",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        //window.location.reload()
        if (this.customer_id != null) {
          this.getCustomer();
          this.getToken();
        }
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: "update is failed",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        if (this.customer_id != null) {
          this.getCustomer();
          this.getToken();
        }
      });

  }

  setcorv(corv_name: string) {
    this.corvname = corv_name;
    console.log(this.corvname);
  }

  changePassword() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50vw";
    this.screenWidth = window.innerWidth;
    dialogConfig.panelClass = "custom-modalbox";
    if (this.screenWidth <= 700) {
      dialogConfig.width = "100vw";
    }
    this.dialog.open(ForgotPasswordComponent, dialogConfig);
  }

  addAddress() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50vw";
    this.screenWidth = window.innerWidth;
    dialogConfig.panelClass = "custom-modalbox";
    if (this.screenWidth <= 700) {
      dialogConfig.width = "100vw";
    }
    this.dialog.open(AddressComponent, dialogConfig);
  }

  updatePhone(otp: string){
    this.custphone = new Custphone();
    this.custphone.customer_id = this.customerDetails.customer_id;
    this.custphone.phone_num = this.PhoneNumber.value;
    this.customerService.updatePhoneNumber( this.custphone, otp, this.csrfToken).subscribe
      (data => {
        console.log(data);
        this.dialog.closeAll();
        Swal.fire({
          icon: 'success',
          title: "PhoneNumber updated successfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.customer_id = this.localService.getData('customerId');
        if (this.customer_id != null) {
          this.localService.clearData();
          this.router.navigate(['view-products']).then(() => {
          window.location.reload();});
        }
      },
        error => {
          console.log(error);
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: "PhoneNumber update is failed",
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
          this.getToken();
        });

  }

  getToken() {
    this.appService.getToken().subscribe(
      data => {
        this.csrfToken = data;
        console.log(data);
      },
      error => {
        console.log(error);
        
      });
  }

  get FullName() {
    return this.custupdateform.get('full_name');
  }

  get Email() {
    return this.custupdateform.get('email');
  }

  get PhoneNum() {
    return this.custupdateform.get('phone_num');
  }

  get Gender() {
    return this.custupdateform.get('gender');
  }

  get State() {
    return this.custupdateform.get('state');
  }

  get AddressId() {
    return this.custupdateform.get('addressId');
  }

  get District() {
    return this.custupdateform.get('district');
  }

  get Taluk() {
    return this.custupdateform.get('taluk');
  }

  get Pincode() {
    return this.custupdateform.get('pincode');
  }

  get Corv() {
    return this.custupdateform.get('corv');
  }

  get Landmark() {
    return this.custupdateform.get('landmark');
  }

  get Otp() {
    return this.custupdateform.get('otp');
  }

  get OldTaluk() {
    return this.custupdateform.get('oldTaluk');
  }

  get PhoneNumber() {
    return this.phoneupdateform.get('phonenum');
  }


}
