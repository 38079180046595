import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Category } from '../category';
import { Subcategory } from '../subcategory';
import { Supersubcategory } from '../supersubcategory';
import { Servererror } from '../servererror';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AppService } from '../app.service';
import { Csrftoken } from '../csrftoken';

@Component({
  selector: 'app-supersubcategory',
  templateUrl: './supersubcategory.component.html',
  styleUrls: ['./supersubcategory.component.css']
})
export class SupersubcategoryComponent implements OnInit {

  categories: Array<Category> = [];
  subcategories: Array<Subcategory> = [];
  category_id = null;
  category = new Category();
  deleteMessage = false;
  superSubcategory = new Supersubcategory();
  subcategory = new Subcategory();
  subcategory_id = null;
  serverError: Servererror = new Servererror();
  csrfToken : Csrftoken = new Csrftoken();

  constructor(private appService: AppService) { }

  ngOnInit() {
    this.deleteMessage = false;
    this.getCategoryList();
    this.getToken();
  }

  categoryform = new FormGroup({
    category_id: new FormControl()
  })

  supersubcategoryupdateform = new FormGroup({
    supersubcategory_id: new FormControl(),
    supersubcategory_name: new FormControl('', [Validators.required]),
    total_products: new FormControl()
  })

  supersubcategoryaddform = new FormGroup({
    supersubcategory_nme: new FormControl('', [Validators.required]),
    add_total_products: new FormControl(),
    subcategory_id: new FormControl('', [Validators.required])
  })

  getCategoryList() {
    this.appService.getCategoryList().subscribe(data => {
      this.categories = data;

      for (let categorie of this.categories) {
        this.category = categorie;
        this.category_id = categorie.category_id;
      }

      this.getsubcategory(this.category_id);

    }, error => {
      console.log(error);
      this.serverError = error.error;
      Swal.fire({
        icon: 'error',
        title: this.serverError.error,
        text: this.serverError.message,
        iconColor: "#892205",
        color: "#2b617d",
        confirmButtonColor: "#2b617d",
        showConfirmButton: true,
        background: "#e5e6e6ed",
      });
    });
  }


  getsubcategory(category_id: number) {
    this.category_id = category_id;
    this.appService.getSubcategoryList(category_id).subscribe(data => {
      this.subcategories = data;
      this.getToken();
    }, error => {
      console.log(error);
      this.serverError = error.error;
      Swal.fire({
        icon: 'error',
        title: this.serverError.error,
        text: this.serverError.message,
        iconColor: "#892205",
        color: "#2b617d",
        confirmButtonColor: "#2b617d",
        showConfirmButton: true,
        background: "#e5e6e6ed",
      });
    });
  }

  deleteSuperSubCategory(supersubcategory: number) {
    this.appService.deleteSuperSubCategory(supersubcategory, this.csrfToken)
      .subscribe(
        data => {
          console.log(data);
          Swal.fire({
            icon: 'success',
            title: "Success",
            text: "Super Subcategory deleted successfully",
            iconColor: "#2b617d",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            timer: 1500,
            timerProgressBar: true,
            background: "#e5e6e6ed",
          });
          this.getsubcategory(this.category_id);
          //window.location.reload();
          //this.getToken();
        }, error => {
          console.log(error);
          this.serverError = error.error;
          Swal.fire({
            icon: 'error',
            title: this.serverError.error,
            text: this.serverError.message,
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
          this.getToken();
        });
  }

  updateSuperSubCategory(supersubcategory_id: number) {
    for (let subcategorie of this.subcategories) {
      console.log(subcategorie.subcategory_name);
      for (let supersubcategory of subcategorie.supersubcategories) {
        console.log(supersubcategory.supersubcategory_name);
        if (supersubcategory_id == supersubcategory.supersubcategory_id) {
          this.superSubcategory = supersubcategory;
          this.subcategory = subcategorie;
          this.subcategory_id = subcategorie.subcategory_id;
        }
      }
    }
  }

  updateSuperSubCat(updsupersubcat) {
    this.superSubcategory = new Supersubcategory();
    this.superSubcategory.supersubcategory_id = this.SuperSubCategoryId.value;
    this.superSubcategory.supersubcategory_name = this.SuperSubCategoryName.value;
    this.superSubcategory.total_products = this.TotalProducts.value;
    this.superSubcategory.subcategory_id = this.subcategory_id;

    this.appService.updateSuperSubCategory(this.superSubcategory, this.csrfToken).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: "Success",
          text: "Super Subcategory updated successfully",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        (<any>$('#myUpdModal')).modal('hide');
        this.getsubcategory(this.category_id);
        //window.location.reload();
        //this.getToken();
      }, error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getsubcategory(this.category_id);
      });
  }

  addSuperSubCat(addsubcat) {
    this.superSubcategory = new Supersubcategory();
    this.superSubcategory.supersubcategory_name = this.SuperSubCategoryNme.value;
    this.superSubcategory.total_products = this.AddTotalProducts.value;
    this.superSubcategory.subcategory_id = this.SubCategoryId.value;

    this.appService.addSuperSubCategory(this.superSubcategory, this.csrfToken).subscribe(
      data => {
        Swal.fire({
          title: "Success",
          text: "Super Subcategory added successfully",
          icon: 'success',
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        (<any>$('#myAddModal')).modal('hide');
        this.getsubcategory(this.category_id);
        //window.location.reload();
        //this.getToken();
      }, error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: this.serverError.error,
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      });
  }

  getToken() {
    this.appService.getToken().subscribe(
      data => {
        this.csrfToken = data;
        console.log(data);
      },
      error => {
        console.log(error);
        
      });
  }

  setSubCategoryId(subcategoryId: number) {
    this.subcategory_id = subcategoryId;
  }

  get CategoryId() {
    return this.categoryform.get('category_id');
  }

  get SuperSubCategoryId() {
    return this.supersubcategoryupdateform.get('supersubcategory_id');
  }

  get SuperSubCategoryName() {
    return this.supersubcategoryupdateform.get('supersubcategory_name');
  }

  get TotalProducts() {
    return this.supersubcategoryupdateform.get('total_products');
  }

  get SuperSubCategoryNme() {
    return this.supersubcategoryaddform.get('supersubcategory_nme');
  }

  get SubCategoryId() {
    return this.supersubcategoryaddform.get('subcategory_id');
  }

  get AddTotalProducts() {
    return this.supersubcategoryaddform.get('add_total_products');
  }

}
