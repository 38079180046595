import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from '../customer.service';
import { Customerdetails } from '../customerdetails';
import { Router } from '@angular/router';
import { Servererror } from '../servererror';
import { Address } from '../address';
import { Observable } from 'rxjs';
import { Taluk } from '../taluk';
import { Corv } from '../corv';
import { PhoneVerify } from '../phone-verify';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AppService } from '../app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Csrftoken } from '../csrftoken';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  constructor(private customerService: CustomerService, private appService: AppService,
    private router: Router, private dialog: MatDialog) { }

  password;
  show = false;
  customerDetails: Customerdetails = new Customerdetails();
  errors: string;
  serverError: Servererror = new Servererror();
  popup;
  addresses: Array<Address> = [];
  defaultState;
  defaultDistrict;
  taluks: Observable<Taluk[]>;
  corvs: Observable<Corv[]>;
  talukname;
  corvname;
  phoneVerify: PhoneVerify = new PhoneVerify();
  public screenWidth: any;
  count = 0;
  csrfToken : Csrftoken = new Csrftoken();

  ngOnInit() {
    this.count = 0;
    this.password = 'password';
    this.defaultState = 'Karnataka';
    this.defaultDistrict = 'Tumkur';

    this.getTaluks();
    this.getToken();
  }

  customersignupform = new FormGroup({
    full_name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]),
    email: new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]),
    phone_num: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]),
    password: new FormControl('', [Validators.required, Validators.pattern("^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{7,15}$")]),
    confirm_password: new FormControl('', [Validators.required]),
    gender: new FormControl(),
    state: new FormControl(),
    district: new FormControl(),
    taluk_name: new FormControl(),
    pincode: new FormControl(),
    destinationplace: new FormControl(),
    landmark: new FormControl(),
    otp: new FormControl()
  });

  getTaluks() {
    this.appService.getTaluksById(1).subscribe(
      data => {
        this.taluks = data;
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: "Taluks are failed to load",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          background: "#e5e6e6ed",
        });
      });
  }

  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

  clickButton(path: string) {
    this.router.navigate([path]);
  }

  createCustomer(customerInformation) {
    this.customerDetails = new Customerdetails();
    this.phoneVerify = new PhoneVerify();
    this.customerDetails.full_name = this.FullName.value;
    this.customerDetails.email = this.Email.value;
    this.customerDetails.phone_num = this.PhoneNum.value;
    this.phoneVerify.phone_no =  this.PhoneNum.value;
    this.customerDetails.password = this.Password.value;
    this.customerDetails.gender = this.Gender.value;

    let address = new Address();
    this.addresses = new Array<Address>();

    address.state = this.defaultState;
    address.district = this.defaultDistrict;
    address.taluk = this.talukname;
    address.pincode = this.Pincode.value;
    address.corv = this.corvname;
    address.landmark = this.Landmark.value;

    if (address != null) {
      this.addresses.push(address);
      this.customerDetails.addresses = this.addresses;
    }
    this.verifyPhone();
  }

  verifyPhone() {
    this.customerService.verifyPhone(this.phoneVerify, this.csrfToken)
      .subscribe
      (data => {
        this.getToken()
        console.log(data);
        this.count = 0;
        this.enterOtp();
      },
        error => {
          console.log(error);
          this.serverError = error.error;
          Swal.fire({
            title: "Phone number verification is failed",
            text: this.serverError.message,
            icon: 'error',
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
          this.getToken();
        });
  }

  verifyOtp(otp: string) {
    this.phoneVerify.otp = otp;
    this.customerService.verifyOtp(this.phoneVerify, this.csrfToken)
      .subscribe
      (data => {
        this.getToken();
        console.log(data);
        this.save(otp);
      },
        error => {
          console.log(error);
          console.log(this.count);
          this.count++;
          this.serverError = error.error;
          Swal.fire({
            title: "OTP verification is failed",
            text: this.serverError.message,
            icon: 'error',
            iconColor: "#892205",
            color: "#2b617d",
            confirmButtonColor: "#2b617d",
            showConfirmButton: true,
            background: "#e5e6e6ed",
          });
          this.getToken();

          if (this.count<3) {
            this.enterOtp()
          } else {
            Swal.fire({
              icon: 'error',
              title: "OTP verification is failed",
              text: "you have exceeded the limit please try again after some time",
              iconColor: "#892205",
              color: "#2b617d",
              confirmButtonColor: "#2b617d",
              showConfirmButton: true,
              background: "#e5e6e6ed",
            });
          }

        });
  }

  async enterOtp() {
    const { value: otp } = await Swal.fire({
      title: 'Enter your OTP',
      input: 'password',
      inputLabel: 'OTP',
      inputPlaceholder: 'Enter your OTP',
      confirmButtonColor: "#2b617d",
      showConfirmButton: true,
      inputAttributes: {
        maxlength: 10,
        autocapitalize: 'off',
        autocorrect: 'off'
      }
    })

    if (otp) {
      this.verifyOtp(otp);
    }else{
      Swal.fire({
        icon: 'error',
        title: "OTP verification is failed",
        text: "please enter OTP and submit",
        iconColor: "#892205",
        color: "#2b617d",
        confirmButtonColor: "#2b617d",
        showConfirmButton: true,
        background: "#e5e6e6ed",
      });
      this.enterOtp();
    }
  }

  save(otp: string) {
    this.customerService.addCustomer(this.customerDetails, otp, this.csrfToken).subscribe(
      data => {
        console.log(data);
        Swal.fire({
          icon: 'success',
          title: "Sucess",
          text: "SignUp is sucessfull",
          iconColor: "#2b617d",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.customerDetails = new Customerdetails();
        this.customersignupform.reset();
        this.dialog.closeAll();
      },
      error => {
        console.log(error)
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: "SignUp if failed",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
        this.getToken();
      });
  }

  

  getcorv(selectedTaluk: Taluk) {
    this.talukname = selectedTaluk.taluk_name;
    console.log(this.talukname);
    this.appService.getCorvs(selectedTaluk.taluk_id).subscribe(
      data => {
        this.corvs = data;
      },
      error => {
        console.log(error);
        this.serverError = error.error;
        Swal.fire({
          icon: 'error',
          title: "City/Village's are failed to load",
          text: this.serverError.message,
          iconColor: "#892205",
          color: "#2b617d",
          confirmButtonColor: "#2b617d",
          showConfirmButton: true,
          timer: 1500,
          timerProgressBar: true,
          background: "#e5e6e6ed",
        });
      });
  }

  getToken() {
    this.appService.getToken().subscribe(
      data => {
        this.csrfToken = data;
        console.log(data);
      },
      error => {
        console.log(error);
        
      });
  }

  setcorv(corv_name: string) {
    this.corvname = corv_name;
    console.log(this.corvname);
  }

  get FullName() {
    return this.customersignupform.get('full_name');
  }

  get Email() {
    return this.customersignupform.get('email');
  }

  get PhoneNum() {
    return this.customersignupform.get('phone_num');
  }

  get Password() {
    return this.customersignupform.get('password');
  }

  get ConfirmPassword() {
    return this.customersignupform.get('confirm_password');
  }

  get Gender() {
    return this.customersignupform.get('gender');
  }

  get State() {
    return this.customersignupform.get('state');
  }

  get District() {
    return this.customersignupform.get('district');
  }

  get Taluk() {
    return this.customersignupform.get('taluk_name');
  }

  get Pincode() {
    return this.customersignupform.get('pincode');
  }

  get Destinationplace() {
    return this.customersignupform.get('destinationplace');
  }

  get Landmark() {
    return this.customersignupform.get('landmark');
  }

  get Otp() {
    return this.customersignupform.get('otp');
  }

}
