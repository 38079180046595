export class Address {
    address_id: number;
    state: string;
    district: string;
    taluk: string;
    pincode: number;
    corv: string;
    landmark: string;
    customer_id: number
}
