import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalService } from './local.service';
import { Csrftoken } from './csrftoken';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  //private baseUrl = 'http://192.168.68.60:8080/api/app';
  //private baseUrl = 'http://localhost:8080/api/app';
  //private baseUrl = 'http://testdemo-env.eba-mfqf3qy3.us-east-1.elasticbeanstalk.com/api/app';
  private baseUrl = 'https://api.esshoppy.com/api/app';
  

  constructor(private http: HttpClient, private localService: LocalService) { }

  getToken(): Observable<any> {
    return this.http.get(`${this.baseUrl}` + '/csrf/token',
      { withCredentials: true }
    );
  }

  getCategoryList(): Observable<any> {
    return this.http.get(`${this.baseUrl}` + '/category-list');
  }

  getCategory(id: number): Observable<any> {
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.get(`${this.baseUrl}/category/${id}`,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) });
  }

  addCategory(value: any, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.post(`${this.baseUrl}/add-category`, value,
      { headers: new HttpHeaders().set('Authorization', tokenBearer)
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
       });
  }

  updateCategory(value: any, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.put(`${this.baseUrl}/update-category`, value,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) 
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
      });
  }

  deleteCategory(id: number, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.delete(`${this.baseUrl}/delete-category/${id}`,
      { headers: new HttpHeaders().set('Authorization', tokenBearer)
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
       });
  }

  getSubcategoryList(id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/subcategory-list/${id}`);
  }

  getSubCategory(id: number): Observable<any> {
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.get(`${this.baseUrl}/subcategory/${id}`,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) });
  }

  addSubCategory(value: any, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.post(`${this.baseUrl}/add-subcategory`, value,
      { headers: new HttpHeaders().set('Authorization', tokenBearer)
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
       });
  }

  updateSubCategory(value: any, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.put(`${this.baseUrl}/update-subcategory`, value,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) 
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
      });
  }

  deleteSubCategory(id: number, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.delete(`${this.baseUrl}/delete-subcategory/${id}`,
      { headers: new HttpHeaders().set('Authorization', tokenBearer)
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
       });
  }

  addSuperSubCategory(value: any, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.post(`${this.baseUrl}/add-supersubcategory`, value,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) 
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
      });
  }

  updateSuperSubCategory(value: any, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.put(`${this.baseUrl}/update-supersubcategory`, value,
      { headers: new HttpHeaders().set('Authorization', tokenBearer)
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
       });
  }

  deleteSuperSubCategory(id: number, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.delete(`${this.baseUrl}/delete-supersubcategory/${id}`,
      { headers: new HttpHeaders().set('Authorization', tokenBearer)
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
       });
  }

  getTaluks(): Observable<any> {
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.get(`${this.baseUrl}/get-taluks`,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) });
  }

  getTaluksById(district_id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/get-taluks/${district_id}`);
  }

  getCorvs(taluk_name: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/get-corvs/${taluk_name}`);
  }

  addTaluk(taluk: object, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.post(`${this.baseUrl}` + '/add-taluk', taluk,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) 
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
      });
  }

  deleteTaluk(talukId: number, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.delete(`${this.baseUrl}/delete-taluk/${talukId}`,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) 
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
      });
  }

  updateTaluk(taluk: object, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.put(`${this.baseUrl}` + '/update-taluk', taluk,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) 
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
      });
  }

  addCorv(corv: object, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.post(`${this.baseUrl}` + '/add-corv', corv,
      { headers: new HttpHeaders().set('Authorization', tokenBearer) 
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
      });
  }

  deleteCorv(corvId: number, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.delete(`${this.baseUrl}/delete-corv/${corvId}`,
      { headers: new HttpHeaders().set('Authorization', tokenBearer)
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
       });
  }

  updateCorv(corv: object, csrfToken: Csrftoken): Observable<any> {
    let csrf_token = csrfToken.token;
    const token: any = this.localService.getData('token');
    const tokenBearer = 'Bearer ' + token;
    return this.http.put(`${this.baseUrl}` + '/update-corv', corv,
      { headers: new HttpHeaders().set('Authorization', tokenBearer)
        .set('X-XSRF-TOKEN', csrf_token),
        withCredentials:true 
       });
  }

}
